import { ReactNode, ComponentType, lazy } from 'react';
import { Loadable } from '../components/Loadable/Loadable';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const lazyWithRetries = <T extends ComponentType<any>>(
  importer: () => Promise<{ default: T }>,
  fallback: ReactNode = null,
) => {
  const retryImport = async () => {
    try {
      return await importer();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // retry 5 times with 2 second delay and backoff factor of 2 (2, 4, 8, 16, 32 seconds)
      for (let i = 0; i < 5; i++) {
        await new Promise((resolve) => setTimeout(resolve, 1000 * 2 ** i));
        // this assumes that the exception will contain this specific text with the url of the module
        // if not, the url will not be able to parse and we'll get an error on that
        // eg. "Failed to fetch dynamically imported module: https://example.com/assets/Home"

        if (error.message.indexOf('Failed to fetch dynamically imported module') === -1) throw error;

        const url = new URL(error.message.replace('Failed to fetch dynamically imported module: ', '').trim());
        // add a timestamp to the url to force a reload the module (and not use the cached version - cache busting)
        url.searchParams.set('t', `${Number(new Date())}`);

        try {
          return await import(/* @vite-ignore */ url.href);
        } catch {
          console.info('retrying import');
        }
      }
      throw error;
    }
  };

  return Loadable(lazy<T>(retryImport), fallback);
};
