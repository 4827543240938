import { ReactNode, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material';
import { FlexColumn } from 'src/components/styled/styled';
import { tokens } from '../assets/tokens/tokens';
import { Snackbar, SnackbarProps } from './Snackbar';
const MAX_SNACKBARS = 3;

type SnackbarContextType = {
  showSnackbar: (
    message: SnackbarProps['message'],
    severity?: SnackbarProps['severity'],
    description?: SnackbarProps['description'],
    action?: SnackbarProps['action'],
  ) => void;
};

export const SnackbarContext = createContext<SnackbarContextType>({ showSnackbar: () => null });

type SnackbarProviderParameters = {
  children: ReactNode;
};

export const SnackbarProvider = ({ children }: SnackbarProviderParameters) => {
  const [snackbars, setSnackbars] = useState<Array<SnackbarProps>>([]);

  const handleClose = useCallback(
    (id: string) => () =>
      setSnackbars((prev) =>
        prev.map((snackbar) => ({ ...snackbar, open: snackbar.id === id ? false : snackbar.open })),
      ),
    [],
  );

  const showSnackbar = useCallback<SnackbarContextType['showSnackbar']>(
    (message, severity, description, action) =>
      setSnackbars((prev) => {
        if (prev.filter((snackbar) => snackbar.open).length > MAX_SNACKBARS - 1) {
          const oldest = prev.find((snackbar) => snackbar.open);
          if (oldest) {
            oldest.open = false;
          }
        }

        const id = Date.now().toString();

        return [...prev, { id, open: true, message, severity, description, action, onClose: handleClose(id) }];
      }),
    [handleClose],
  );

  const contextValue = useMemo(() => ({ showSnackbar }), [showSnackbar]);

  useEffect(() => {
    const timer = setInterval(() => {
      setSnackbars((prev) => prev.filter((snackbar) => snackbar.open));
    }, 10000);

    return () => clearInterval(timer);
  }, []);

  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
      <StyledSnackbarContainer>
        {snackbars.map((props) => (
          <Snackbar key={props.id} {...props} />
        ))}
      </StyledSnackbarContainer>
    </SnackbarContext.Provider>
  );
};

const StyledSnackbarContainer = styled(FlexColumn)({
  position: 'fixed',
  right: 'auto',
  left: '24px',
  bottom: '24px',
  alignItems: 'flex-end',
  gap: tokens.spacing[2],
  zIndex: tokens.zIndex.snackbar,
});
