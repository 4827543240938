import { memo } from 'react';
import { ErrorPage } from 'src/components/ErrorPage/ErrorPage';

export const ErrorBrowserVersionPage = memo(() => (
  <ErrorPage
    title='426. Upgrade required'
    description='Browser version you are using is unsupported, please use newer version'
  />
));

ErrorBrowserVersionPage.displayName = 'ErrorBrowserVersionPage';
