import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  ThemeProvider,
  styled,
} from '@mui/material';
import { forwardRef, memo } from 'react';
import { utilityClassnames } from 'src/util/utilityClassnames';
import { clx } from 'src/util/classnames';
import { theme } from '../assets/theme';
import { tokens } from '../assets/tokens/tokens';

export interface IconButtonProps extends Omit<MuiIconButtonProps, 'variant'> {
  variant?: 'contained' | 'outlined' | 'text';
  size?: 'small' | 'medium' | 'large';
  color?: 'primary' | 'secondary' | 'error';
  anchor?: boolean;
}

export const IconButton = memo(
  forwardRef<HTMLButtonElement, IconButtonProps>(
    ({ variant = 'contained', size = 'medium', color = 'primary', anchor, ...props }, ref) => {
      return (
        <ThemeProvider theme={theme}>
          <StyledIconButton
            disableRipple
            ref={ref}
            variant={variant}
            size={size}
            color={color}
            {...props}
            className={clx(props.className, { 'Mui-expanded': anchor })}
          />
        </ThemeProvider>
      );
    },
  ),
);

IconButton.displayName = 'IconButton';

interface StyledIconButtonProps {
  variant: IconButtonProps['variant'];
}

const StyledIconButton = styled(MuiIconButton, {
  name: 'IconButton',
  shouldForwardProp: (prop) => prop !== 'endIcon',
})<StyledIconButtonProps>(({ variant }) => ({
  flex: '0 0 auto',
  boxShadow: 'none',
  border: 'none',
  padding: 'unset',

  '&.MuiIconButton-sizeLarge': {
    width: '32px',
    height: '32px',
    padding: tokens.spacing[1.5],
    borderRadius: tokens.borderRadius[1],

    '& span, & svg': {
      width: '20px',
      height: '20px',
    },
  },

  '&.MuiIconButton-sizeMedium': {
    width: '24px',
    height: '24px',
    padding: tokens.spacing[0.5],
    borderRadius: tokens.borderRadius[1],

    '&.MuiIconButton-edgeEnd': {
      marginRight: `calc(-${tokens.spacing[0.5]})`,
    },

    '& span, & svg': {
      width: '20px',
      height: '20px',
    },
  },

  '&.MuiIconButton-sizeSmall': {
    width: '16px',
    height: '16px',
    padding: tokens.spacing[0.5],
    borderRadius: tokens.borderRadius[0.5],

    '& span, & svg': {
      width: '12px',
      height: '12px',
    },
  },

  '&.Mui-focusVisible': {
    ':after': {
      content: '""',
      position: 'absolute',
      top: `-3px`,
      right: `-3px`,
      bottom: `-3px`,
      left: `-3px`,
      border: `1px solid ${tokens.colors.tertiary.blue[500]}`,
      borderRadius: tokens.borderRadius[1.5],
    },
  },

  ...(variant === 'contained' && {
    '&.MuiIconButton-colorPrimary': {
      background: tokens.colors.primary.light,
      color: tokens.colors.primary.white,

      [`&:hover, &.${utilityClassnames.hover}`]: {
        background: tokens.colors.secondary.purple[600],
        color: tokens.colors.primary.white,
      },

      '&.Mui-expanded': {
        background: tokens.colors.secondary.purple[600],
        color: tokens.colors.primary.white,
      },

      [`&:active, &.${utilityClassnames.active}`]: {
        background: tokens.colors.secondary.purple[700],
        color: tokens.colors.primary.white,
      },

      '&:disabled, &.Mui-disabled': {
        background: tokens.colors.secondary.gray[30050],
        color: tokens.colors.secondary.gray[800],
      },
    },

    '&.MuiIconButton-colorError': {
      background: tokens.colors.tertiary.red[400],
      color: tokens.colors.primary.white,

      [`&:hover, &.${utilityClassnames.hover}`]: {
        background: tokens.colors.tertiary.red[600],
        color: tokens.colors.primary.white,
      },

      '&.Mui-expanded': {
        background: tokens.colors.tertiary.red[600],
        color: tokens.colors.primary.white,
      },

      [`&:active, &.${utilityClassnames.active}`]: {
        background: tokens.colors.tertiary.red[700],
        color: tokens.colors.primary.white,
      },

      '&:disabled, &.Mui-disabled': {
        background: tokens.colors.secondary.gray[30050],
        color: tokens.colors.secondary.gray[800],
      },
    },
  }),

  ...(variant === 'outlined' && {
    '&.MuiIconButton-colorPrimary': {
      border: `1px solid ${tokens.colors.secondary.gray[600]}`,
      background: 'transparent',
      color: tokens.colors.primary.dark,

      [`&:hover, &.${utilityClassnames.hover}`]: {
        border: `1px solid ${tokens.colors.secondary.gray[600]}`,
        background: tokens.colors.secondary.purple[20040],
        color: tokens.colors.primary.dark,
      },

      '&.Mui-expanded': {
        border: `1px solid ${tokens.colors.secondary.gray[600]}`,
        background: tokens.colors.secondary.purple[20040],
        color: tokens.colors.primary.dark,
      },

      [`&:active, &.${utilityClassnames.active}`]: {
        border: `1px solid ${tokens.colors.secondary.gray[600]}`,
        background: tokens.colors.secondary.purple[30050],
        color: tokens.colors.primary.dark,
      },

      '&:disabled, &.Mui-disabled': {
        border: `1px solid ${tokens.colors.secondary.gray[400]}`,
        background: 'transparent',
        color: tokens.colors.secondary.gray[600],
      },
    },

    '&.MuiIconButton-colorSecondary': {
      border: `1px solid ${tokens.colors.secondary.gray[300]}`,
      background: 'transparent',
      color: tokens.colors.secondary.gray[800],

      [`&:hover, &.${utilityClassnames.hover}`]: {
        border: `1px solid ${tokens.colors.secondary.gray[300]}`,
        background: tokens.colors.secondary.gray[20040],
        color: tokens.colors.secondary.gray[900],
      },

      '&.Mui-expanded': {
        border: `1px solid ${tokens.colors.secondary.gray[300]}`,
        background: tokens.colors.secondary.gray[20040],
        color: tokens.colors.secondary.gray[900],
      },

      [`&:active, &.${utilityClassnames.active}`]: {
        border: `1px solid ${tokens.colors.secondary.gray[300]}`,
        background: tokens.colors.secondary.gray[30050],
        color: tokens.colors.secondary.gray[900],
      },

      '&:disabled, &.Mui-disabled': {
        border: `1px solid ${tokens.colors.secondary.gray[30050]}`,
        background: 'transparent',
        color: tokens.colors.secondary.gray[500],
      },
    },

    '&.MuiIconButton-colorError': {
      border: `1px solid ${tokens.colors.tertiary.red[400]}`,
      background: 'transparent',
      color: tokens.colors.tertiary.red[400],

      [`&:hover, &.${utilityClassnames.hover}`]: {
        border: `1px solid ${tokens.colors.tertiary.red[400]}`,
        background: tokens.colors.tertiary.red[100],
        color: tokens.colors.tertiary.red[400],
      },

      '&.Mui-expanded': {
        border: `1px solid ${tokens.colors.tertiary.red[400]}`,
        background: tokens.colors.tertiary.red[100],
        color: tokens.colors.tertiary.red[400],
      },

      [`&:active, &.${utilityClassnames.active}`]: {
        border: `1px solid ${tokens.colors.tertiary.red[400]}`,
        background: tokens.colors.tertiary.red[200],
        color: tokens.colors.tertiary.red[400],
      },

      '&:disabled, &.Mui-disabled': {
        border: `1px solid ${tokens.colors.secondary.gray[400]}`,
        background: 'transparent',
        color: tokens.colors.secondary.gray[600],
      },
    },
  }),

  ...(variant === 'text' && {
    '&.MuiIconButton-colorPrimary': {
      background: 'transparent',
      color: tokens.colors.primary.light,

      [`&:hover, &.${utilityClassnames.hover}`]: {
        background: tokens.colors.secondary.purple[20040],
        color: tokens.colors.primary.light,
      },

      '&.Mui-expanded': {
        background: tokens.colors.secondary.purple[20040],
        color: tokens.colors.primary.light,
      },

      [`&:active, &.${utilityClassnames.active}`]: {
        background: tokens.colors.secondary.purple[30050],
        color: tokens.colors.secondary.purple[600],
      },

      '&:disabled, &.Mui-disabled': {
        background: 'transparent',
        color: tokens.colors.secondary.gray[600],
      },
    },

    '&.MuiIconButton-colorSecondary': {
      background: 'transparent',
      color: tokens.colors.secondary.gray[800],

      [`&:hover, &.${utilityClassnames.hover}`]: {
        background: tokens.colors.secondary.gray[20040],
        color: tokens.colors.secondary.gray[900],
      },

      '&.Mui-expanded': {
        background: tokens.colors.secondary.gray[20040],
        color: tokens.colors.secondary.gray[900],
      },

      [`&:active, &.${utilityClassnames.active}`]: {
        background: tokens.colors.secondary.gray[30050],
        color: tokens.colors.secondary.gray[900],
      },

      '&:disabled, &.Mui-disabled': {
        background: 'transparent',
        color: tokens.colors.secondary.gray[500],
      },
    },

    '&.MuiIconButton-colorError': {
      background: 'transparent',
      color: tokens.colors.tertiary.red[400],

      [`&:hover, &.${utilityClassnames.hover}`]: {
        background: tokens.colors.tertiary.red[100],
        color: tokens.colors.tertiary.red[400],
      },

      '&.Mui-expanded': {
        background: tokens.colors.tertiary.red[100],
        color: tokens.colors.tertiary.red[400],
      },

      [`&:active, &.${utilityClassnames.active}`]: {
        background: tokens.colors.tertiary.red[200],
        color: tokens.colors.tertiary.red[600],
      },

      '&:disabled, &.Mui-disabled': {
        background: 'transparent',
        color: tokens.colors.secondary.gray[600],
      },
    },

    '&.MuiIconButton-colorInfo': {
      background: 'transparent',
      color: tokens.colors.secondary.gray[300],

      [`&:hover, &.${utilityClassnames.hover}`]: {
        color: tokens.colors.primary.white,
      },

      '&.Mui-expanded': {
        color: tokens.colors.primary.white,
      },

      [`&:active, &.${utilityClassnames.active}`]: {
        color: tokens.colors.primary.white,
      },

      '&:disabled, &.Mui-disabled': {
        color: tokens.colors.secondary.gray[600],
      },
    },
  }),
}));
