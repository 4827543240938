import { alpha } from '@mui/material';

const colorsBase = {
  primary: {
    white: '#FFFFFF',
    dark: '#1E1A32',
    light: '#482B82',
  },
  secondary: {
    purple: {
      100: '#F2EFFB',
      200: '#C8BFD9',
      300: '#B9AAD7',
      400: '#9180B4',
      500: '#6D559B',
      600: '#3C246D',
      700: '#3A2268',
      800: '#331E5D',
      900: '#1E1452',
      1000: '#343148',
    },
    gray: {
      100: '#F3F3F5',
      200: '#DBDCE2',
      300: '#C4C6CF',
      400: '#AEB0BC',
      500: '#989BA8',
      600: '#838694',
      700: '#6E7180',
      800: '#5B5E6B',
      900: '#373941',
    },
  },
  tertiary: {
    blue: {
      100: '#EBF5FF',
      200: '#C2E0FF',
      300: '#96CCFC',
      400: '#66B8F5',
      500: '#25A4EA',
      600: '#0090DA',
      700: '#007BC5',
      800: '#0067AB',
      900: '#00538D',
    },
    red: {
      100: '#FFEBEA',
      200: '#FECFCD',
      300: '#F29F9F',
      400: '#E50C00',
      500: '#CC0B00',
      600: '#C60B00',
      700: '#BE0E04',
      800: '#B20900',
      900: '#97130C',
    },
    green: {
      100: '#EEF5F1',
      200: '#CDE2D7',
      300: '#AECEBD',
      400: '#91BAA5',
      500: '#75A58D',
      600: '#5D9178',
      700: '#487C64',
      800: '#376851',
      900: '#295340',
    },
    amber: {
      100: '#FFF1E1',
      200: '#FFD5A7',
      300: '#FBBA71',
      400: '#EEA03E',
      500: '#DB8800',
      600: '#C67200',
      700: '#AE5E00',
      800: '#944B00',
      900: '#793B00',
    },
  },
  presentation: {
    purple: {
      1: '#7446D1',
      2: '#38324F',
      3: '#201C32',
    },
  },
};

export const colors = {
  primary: {
    ...colorsBase.primary,
    dark80: alpha(colorsBase.primary.dark, 0.8),
  },
  secondary: {
    ...colorsBase.secondary,
    purple: {
      ...colorsBase.secondary.purple,
      20040: alpha(colorsBase.secondary.purple[200], 0.4),
      30050: alpha(colorsBase.secondary.purple[300], 0.5),
    },
    gray: {
      ...colorsBase.secondary.gray,
      20040: alpha(colorsBase.secondary.gray[200], 0.4),
      30050: alpha(colorsBase.secondary.gray[300], 0.5),
      70080: alpha(colorsBase.secondary.gray[700], 0.8),
    },
  },
  tertiary: {
    ...colorsBase.tertiary,
  },
  presentation: {
    purple: {
      ...colorsBase.presentation.purple,
      140: alpha(colorsBase.presentation.purple[1], 0.4),
    },
  },
};
