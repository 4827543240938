import { Suspense, memo } from 'react';
import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import * as Sentry from '@sentry/react';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import minMax from 'dayjs/plugin/minMax';
import duration from 'dayjs/plugin/duration';
import dayjs from 'dayjs';
import { LicenseInfo } from '@mui/x-license';
import { initApi } from './api/initApi';
import { routes } from './routes';
import { theme } from './theme/theme';

initApi();

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_X_PRO_LICENSE_KEY);

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(minMax);
dayjs.extend(duration);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: (failureCount, error) => {
        return failureCount < 2 && error instanceof Error && error.message === 'Network Error';
      },
      gcTime: 0,
    },
    mutations: {
      retry: 0,
    },
  },
});

const App = memo(() => {
  return (
    <Suspense fallback={null}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RouterProvider router={routes} />
        </ThemeProvider>
      </QueryClientProvider>
    </Suspense>
  );
});

App.displayName = 'App';

export const AppWithProfiler = Sentry.withProfiler(App);
