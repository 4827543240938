import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Paths } from 'src/paths';
import { Portal, UserInfo } from 'src/types';
import { useGetUserInfo } from '../api/hooks/UserAuthenticationService';

export const useUserInfo = () => {
  const { pathname } = useLocation();
  const { data, refetch, isFetching, isError } = useGetUserInfo();

  const userInfo = useMemo(() => {
    if (!data) return undefined;

    const isAdminPortal = pathname.startsWith(`/${Paths.ADMIN}`);
    const isAgentPortal = pathname.startsWith(`/${Paths.AGENT}`);
    const user = isAdminPortal ? data.me : data.impersonate ?? data.me;
    const portal = isAdminPortal ? Portal.Admin : isAgentPortal ? Portal.Agent : Portal.Client;
    const portalPath = isAdminPortal ? Paths.ADMIN : Paths.AGENT;

    // isVerified always have to check on 'me' data
    user.isVerified = data.me.isVerified;

    return {
      ...user,
      isAdminPortal,
      isAgentPortal,
      portal,
      portalPath,
      impersonateId: data.impersonateId,
      checkPermission: data.checkPermission,
    } satisfies UserInfo;
  }, [data, pathname]);

  return {
    userInfo,
    isFetching,
    isError,
    refetch,
  };
};
