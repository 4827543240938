/* eslint-disable react-refresh/only-export-components */
import * as Sentry from '@sentry/react';
import axios, { AxiosError } from 'axios';
import { memo, useEffect, useState } from 'react';
import { Navigate, Outlet, createBrowserRouter, useLocation, useNavigate } from 'react-router-dom';
import { COMPARISON_EXPORT_PAGE } from 'src/pages/AdminPortal/PresentationExportApp/util/constants';
import { SnackbarProvider } from 'src/components/Snackbar/SnackbarContext';
import { ProtectedRoute } from 'src/components/ProtectedRoute/ProtectedRoute';
import { ErrorBoundaryPage } from 'src/pages/ErrorPages/ErrorBoundaryPage/ErrorBoundaryPage';
import { ErrorAccessDeniedPage } from 'src/pages/ErrorPages/ErrorAccessDeniedPage/ErrorAccessDeniedPage';
import { ErrorNotFoundPage } from 'src/pages/ErrorPages/ErrorNotFoundPage/ErrorNotFoundPage';
import { ErrorBrowserVersionPage } from 'src/pages/ErrorPages/ErrorBrowserVersionPage/ErrorBrowserVersionPage';
import { Permission } from './generatedApi';
import { useUserInfo } from './hooks/useUserInfo';
import { isBrowserVersionSupported } from './util/isBrowserVersionSupported';
import { lazyWithRetries } from './util/lazyWithRetries';
import { ReleaseDialog } from './widgets/ReleaseDialog/ReleaseDialog';
import { useSnackbars } from './hooks/useSnackbars';
import { Paths } from './paths';

const Root = memo(() => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [releaseDialogOpen, setReleaseDialogOpen] = useState(false);
  const { userInfo, isFetching } = useUserInfo();
  const { showSnackbar } = useSnackbars();

  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      (res) => {
        return res;
      },
      (error: AxiosError) => {
        if (error.response?.status === 412) {
          setReleaseDialogOpen(true);
          return;
        }
        if (error.response?.status === 502) {
          showSnackbar('Deploy is in progress', 'warning');
          return;
        }

        throw error;
      },
    );

    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isBrowserVersionSupported()) navigate(Paths.ERROR_BROWSER_VERSION);
  }, [navigate]);

  useEffect(() => {
    const isRootPath = pathname === '/';
    if (isRootPath && !isFetching) {
      if (userInfo) {
        const checkPermissionWithoutMerge = (permission: Permission): boolean => {
          return userInfo.permissions.includes(permission);
        };
        if (checkPermissionWithoutMerge(Permission.VIEW_ADMIN_PLATFORM)) {
          navigate(`/${Paths.ADMIN}/${Paths.CLIENTS}`);
          return;
        }
        if (checkPermissionWithoutMerge(Permission.VIEW_AGENT_PLATFORM)) {
          navigate(Paths.AGENT);
          return;
        }
        if (checkPermissionWithoutMerge(Permission.VIEW_OWN_SURVEY)) {
          navigate(`/${Paths.SURVEY}`);
          return;
        }
      } else navigate(`/${Paths.SIGNIN}`);
    }
  }, [pathname, navigate, userInfo, isFetching]);

  return (
    <Sentry.ErrorBoundary fallback={<ErrorBoundaryPage />}>
      <SnackbarProvider>
        <Outlet context={{ userInfo, isFetching }} />
        <ReleaseDialog open={releaseDialogOpen} />
      </SnackbarProvider>
    </Sentry.ErrorBoundary>
  );
});

Root.displayName = 'Root';

const SurveyPage = lazyWithRetries(() =>
  import('./pages/SurveyPage/SurveyPage').then((module) => ({
    default: module.SurveyPage,
  })),
);

const SurveyRedirectPage = lazyWithRetries(() =>
  import('./pages/SurveyPage/SurveyRedirectPage').then((module) => ({
    default: module.SurveyRedirectPage,
  })),
);

const SigninPage = lazyWithRetries(() =>
  import('./pages/StartingPages/SigninPage/SigninPage').then((module) => ({
    default: module.SigninPage,
  })),
);

const ResetPasswordPage = lazyWithRetries(() =>
  import('./pages/StartingPages/ResetPasswordPage/ResetPasswordPage').then((module) => ({
    default: module.ResetPasswordPage,
  })),
);

const JoinPage = lazyWithRetries(() =>
  import('./pages/StartingPages/JoinPage/JoinPage').then((module) => ({
    default: module.JoinPage,
  })),
);

const OTPPage = lazyWithRetries(() =>
  import('./pages/StartingPages/OTPPage/OTPPage').then((module) => ({
    default: module.OTPPage,
  })),
);

const Portal = lazyWithRetries(() =>
  import('src/components/Portal/Portal').then((module) => ({
    default: module.Portal,
  })),
);

const AdminClientsPage = lazyWithRetries(() =>
  import('./pages/AdminPortal/ClientsApp/ClientsPage/ClientsPage').then((module) => ({
    default: module.ClientsPage,
  })),
);

const AdminClientPage = lazyWithRetries(() =>
  import('./pages/AdminPortal/ClientsApp/ClientPage/ClientPage').then((module) => ({
    default: module.ClientPage,
  })),
);

const CalculatorPage = lazyWithRetries(() =>
  import('./pages/AdminPortal/CalculatorPage/CalculatorPageWrapper').then((module) => ({
    default: module.CalculatorPageWrapper,
  })),
);

const ComparisonExportPage = lazyWithRetries(() =>
  import('src/pages/AdminPortal/PresentationExportApp/ComparisonExportPage').then((module) => ({
    default: module.ComparisonExportPage,
  })),
);

const DiscoveryMatrixExportPage = lazyWithRetries(() =>
  import('src/pages/AdminPortal/PresentationExportApp/DiscoveryMatrixExportPage/DiscoveryMatrixExportPage').then(
    (module) => ({
      default: module.DiscoveryMatrixExportPage,
    }),
  ),
);

const IllustrationsPage = lazyWithRetries(() =>
  import('./pages/AdminPortal/IllustrationsApp/IllustrationsPage/IllustrationsPage').then((module) => ({
    default: module.IllustrationsPage,
  })),
);

const CarriersListPage = lazyWithRetries(() =>
  import('./pages/AdminPortal/CarriersApp/CarriersListPage/CarriersListPage').then((module) => ({
    default: module.CarriersListPage,
  })),
);

const CreateCarrierPage = lazyWithRetries(() =>
  import('./pages/AdminPortal/CarriersApp/CarrierCreatePage/CarrierCreatePage').then((module) => ({
    default: module.CarrierCreatePage,
  })),
);

const CarrierPage = lazyWithRetries(() =>
  import('./pages/AdminPortal/CarriersApp/CarrierPage/CarrierPage').then((module) => ({
    default: module.CarrierPage,
  })),
);

const AdminReportsPage = lazyWithRetries(() =>
  import('./pages/AdminPortal/ReportsApp/ReportsPage/ReportsPage').then((module) => ({
    default: module.ReportsPage,
  })),
);

const DocumentationPage = lazyWithRetries(() =>
  import('./pages/DocumentationPage/DocumentationPage').then((module) => ({
    default: module.DocumentationPage,
  })),
);

const AdminMarketingPage = lazyWithRetries(() =>
  import('./pages/AdminPortal/MarketingApp/MarketingPage/MarketingPage').then((module) => ({
    default: module.MarketingPage,
  })),
);

const AdminClientInformationReportPage = lazyWithRetries(() =>
  import('./pages/AdminPortal/ReportsApp/ClientInformationReportPage/ClientInformationReportPage').then((module) => ({
    default: module.ClientInformationReportPage,
  })),
);

const AgentsListPage = lazyWithRetries(() =>
  import('./pages/AdminPortal/AgentApp/AgentsListPage/AgentsListPage').then((module) => ({
    default: module.AgentsListPage,
  })),
);

const AdminSurveyPage = lazyWithRetries(() =>
  import('./pages/AdminPortal/ClientsApp/SurveyPage/SurveyPage').then((module) => ({
    default: module.SurveyPage,
  })),
);

const AgentClientsPage = lazyWithRetries(() =>
  import('./pages/AgentPortal/ClientsPage/ClientsPage').then((module) => ({
    default: module.ClientsPage,
  })),
);

const AgentClientPage = lazyWithRetries(() =>
  import('./pages/AgentPortal/ClientPage/ClientPage').then((module) => ({
    default: module.ClientPage,
  })),
);

const AgentSurveyPage = lazyWithRetries(() =>
  import('./pages/AgentPortal/SurveyPage/SurveyPage').then((module) => ({
    default: module.SurveyPage,
  })),
);

const AgentReportsPage = lazyWithRetries(() =>
  import('./pages/AgentPortal/ReportsApp/ReportsPage/ReportsPage').then((module) => ({
    default: module.ReportsPage,
  })),
);

const AgentClientInformationReportPage = lazyWithRetries(() =>
  import('./pages/AgentPortal/ReportsApp/ClientInformationReportPage/ClientInformationReportPage').then((module) => ({
    default: module.ClientInformationReportPage,
  })),
);

const AgentMarketingPage = lazyWithRetries(() =>
  import('./pages/AgentPortal/MarketingApp/MarketingPage/MarketingPage').then((module) => ({
    default: module.MarketingPage,
  })),
);

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const routes = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorNotFoundPage />,
    children: [
      {
        path: Paths.ERROR_RUNTIME,
        element: <ErrorBoundaryPage />,
      },
      {
        path: Paths.ERROR_NOT_FOUND,
        element: <ErrorNotFoundPage />,
      },
      {
        path: Paths.ERROR_ACCESS_DENIED,
        element: <ErrorAccessDeniedPage />,
      },
      {
        path: Paths.ERROR_BROWSER_VERSION,
        element: <ErrorBrowserVersionPage />,
      },
      {
        path: Paths.SURVEY,
        element: (
          <ProtectedRoute permission={Permission.VIEW_OWN_SURVEY}>
            <SurveyPage />
          </ProtectedRoute>
        ),
      },
      {
        path: `${Paths.SURVEY}/:surveyId`,
        element: (
          <ProtectedRoute onlyUserCheck>
            <SurveyRedirectPage />
          </ProtectedRoute>
        ),
      },
      {
        path: Paths.SIGNIN,
        element: <SigninPage />,
      },
      {
        path: Paths.RESET_PASSWORD,
        element: <ResetPasswordPage />,
      },
      {
        path: Paths.JOIN,
        element: <JoinPage />,
      },
      {
        path: Paths.OTP,
        element: <OTPPage />,
      },
      {
        path: Paths.ADMIN,
        element: (
          <ProtectedRoute permission={Permission.VIEW_ADMIN_PLATFORM}>
            <Portal />
          </ProtectedRoute>
        ),
        children: [
          {
            path: Paths.CLIENTS,
            element: <AdminClientsPage />,
          },
          {
            path: `${Paths.CLIENTS}/:clientId`,
            element: <AdminClientPage />,
          },
          {
            path: `${Paths.CLIENTS}/:clientId/${Paths.DASHBOARDS}/:dashboardId`,
            element: <CalculatorPage />,
          },
          {
            path: `${Paths.CLIENTS}/:clientId/${Paths.DASHBOARDS}/:dashboardId/${Paths.PRODUCTS_COMPARISON_EXPORT}`,
            element: <ComparisonExportPage type={COMPARISON_EXPORT_PAGE.PRODUCTS_COMPARISON} />,
          },
          {
            // eslint-disable-next-line max-len
            path: `${Paths.CLIENTS}/:clientId/${Paths.DASHBOARDS}/:dashboardId/${Paths.PRODUCT_VS_TRADITIONAL_COMPARISON_EXPORT}`,
            element: <ComparisonExportPage type={COMPARISON_EXPORT_PAGE.PRODUCTS_VS_TRADITIONAL} />,
          },
          {
            path: Paths.DISCOVERY_MATRIX_EXPORT,
            element: <DiscoveryMatrixExportPage />,
          },

          {
            path: Paths.CALCULATOR,
            element: <CalculatorPage />,
          },
          {
            path: `${Paths.CALCULATOR}/:dashboardId`,
            element: <CalculatorPage />,
          },
          {
            path: Paths.ILLUSTRATIONS,
            children: [
              {
                index: true,
                element: <IllustrationsPage />,
              },
            ],
          },
          {
            path: Paths.CARRIERS,
            element: <CarriersListPage />,
          },
          {
            path: `${Paths.CARRIERS}/${Paths.CREATE_NEW}`,
            element: <CreateCarrierPage />,
          },
          {
            path: `${Paths.CARRIERS}/:carrierId`,
            element: <CarrierPage />,
          },
          {
            path: Paths.REPORTS,
            children: [
              {
                index: true,
                element: <AdminReportsPage />,
              },
              {
                path: Paths.CLIENTS_INFORMATION_REPORT,
                element: <AdminClientInformationReportPage />,
              },
            ],
          },
          {
            path: Paths.DOCUMENTATION,
            element: <DocumentationPage />,
          },
          {
            path: `${Paths.DOCUMENTATION}/:userGuideId`,
            element: <DocumentationPage />,
          },
          {
            path: Paths.MARKETING,
            element: <AdminMarketingPage />,
          },
          {
            path: Paths.AGENTS,
            element: <AgentsListPage />,
          },
          {
            path: `${Paths.SURVEY}/:surveyId`,
            element: <AdminSurveyPage />,
          },
          {
            path: '*',
            element: <Navigate to={Paths.CLIENTS} />,
          },
        ],
      },
      {
        path: Paths.AGENT,
        element: (
          <ProtectedRoute permission={Permission.VIEW_AGENT_PLATFORM}>
            <Portal />
          </ProtectedRoute>
        ),
        children: [
          {
            path: Paths.CLIENTS,
            element: <AgentClientsPage />,
          },
          {
            path: `${Paths.CLIENTS}/:clientId`,
            element: <AgentClientPage />,
          },
          {
            path: `${Paths.SURVEY}/:surveyId`,
            element: <AgentSurveyPage />,
          },
          {
            path: `${Paths.CLIENTS}/:clientId/${Paths.DASHBOARDS}/:dashboardId`,
            element: <CalculatorPage />,
          },
          {
            path: Paths.REPORTS,
            children: [
              {
                index: true,
                element: <AgentReportsPage />,
              },
              {
                path: Paths.CLIENTS_INFORMATION_REPORT,
                element: <AgentClientInformationReportPage />,
              },
            ],
          },
          {
            path: Paths.DOCUMENTATION,
            element: <DocumentationPage />,
          },
          {
            path: `${Paths.DOCUMENTATION}/:userGuideId`,
            element: <DocumentationPage />,
          },
          {
            path: Paths.MARKETING,
            element: <AgentMarketingPage />,
          },
        ],
      },
      {
        path: '*',
        element: <ErrorNotFoundPage />,
      },
    ],
  },
]);
