import { MutableRefObject, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useEffectOnce } from './useEffectOnce';

export const useIsOverflowed = (ref: MutableRefObject<HTMLSpanElement | null>, checkOnLayoutEffect = true) => {
  const [isOverflowed, setIsOverflowed] = useState(false);

  const checkOverflow = useCallback(() => {
    const wrapperElement = ref?.current;
    if (wrapperElement) {
      setIsOverflowed(wrapperElement.offsetWidth < wrapperElement.scrollWidth);
    }
  }, [ref]);

  useEffectOnce(checkOverflow);

  useLayoutEffect(() => {
    if (checkOnLayoutEffect) {
      checkOverflow();
    }
  }, [checkOnLayoutEffect, checkOverflow]);

  return useMemo(
    () => ({
      isOverflowed,
      checkOverflow,
    }),
    [isOverflowed, checkOverflow],
  );
};
