import * as Sentry from '@sentry/react';
import {
  ActivateUserRequest,
  LoginForm,
  GenerateRestoreTokenRequest,
  RestorePasswordRequest,
  UserAuthenticationService,
  UserResponse,
  VerifyOTPRequest,
  type Permission,
} from 'src/generatedApi';
import { UserInfo } from 'src/types';

export const loginUser = (formData: LoginForm) => UserAuthenticationService.login({ formData });

export const logoutUser = () => {
  return UserAuthenticationService.logout().then(() => {
    Sentry.setUser(null);
  });
};

export const sendOTP = () => UserAuthenticationService.sendOtp();

export const verifyOTP = (requestBody: VerifyOTPRequest) => UserAuthenticationService.verifyOtp({ requestBody });

type GetUserInfo = {
  me: UserResponse;
  impersonate: UserResponse | null;
} & Pick<UserInfo, 'impersonateId' | 'checkPermission'>;

export const getUserInfo = async (): Promise<GetUserInfo> => {
  const res = await UserAuthenticationService.getMe();
  Sentry.setUser({
    id: res.me.id,
    email: res.me.email,
  });

  const permissions = [...res.me.permissions, ...(res.impersonate?.permissions ?? [])];
  const checkPermission = (permission: Permission): boolean => permissions.includes(permission);

  return {
    me: res.me,
    impersonate: res.impersonate,
    impersonateId: res.impersonate?.id ?? null,
    checkPermission,
  };
};

export const sendRestoreTokenForPassword = (requestBody: GenerateRestoreTokenRequest) =>
  UserAuthenticationService.sendRestoreTokenForPassword({ requestBody });

export const restorePassword = (requestBody: RestorePasswordRequest) =>
  UserAuthenticationService.restorePassword({ requestBody });

export const activateUser = (requestBody: ActivateUserRequest) =>
  UserAuthenticationService.activateByInvitation({ requestBody });

export const getInvitationInfo = (invitationToken: string) =>
  UserAuthenticationService.getInvitationInfo({ invitationToken });
