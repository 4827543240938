import { MouseEvent, ReactElement, memo, useCallback } from 'react';
import {
  Snackbar as MuiSnackbar,
  SnackbarProps as MuiSnackbarProps,
  Slide,
  ThemeProvider,
  styled,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { FlexColumn, FlexRow } from 'src/components/styled/styled';
import { theme } from '../assets/theme';
import { tokens } from '../assets/tokens/tokens';
import { Typography } from '../Typography/Typography';
import { IconButton } from '../IconButton/IconButton';
import { SvgIcon } from '../SvgIcon/SvgIcon';
import { ButtonProps } from '../Button';

const AUTODISMISS_TIMEOUT = 4000;

export interface SnackbarProps extends MuiSnackbarProps {
  id: string;
  severity?: 'default' | 'info' | 'success' | 'warning' | 'error';
  description?: string;
  action?: ReactElement<ButtonProps>;
  onClose: () => void;
}

export const Snackbar = memo<SnackbarProps>(({ severity = 'default', action, description, onClose, ...props }) => {
  const handleActionClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      action?.props?.onClick?.(event);
      onClose();
    },
    [action, onClose],
  );

  const handleSnackbarClose = useCallback<Exclude<MuiSnackbarProps['onClose'], undefined>>(
    (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      if (reason === 'escapeKeyDown') {
        event?.preventDefault();
      }

      onClose();
    },
    [onClose],
  );

  return (
    <ThemeProvider theme={theme}>
      <StyledSnackbar
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'right' } as TransitionProps}
        transitionDuration={{ enter: 600, appear: 600, exit: 600 }}
        autoHideDuration={AUTODISMISS_TIMEOUT}
        onClose={handleSnackbarClose}
        {...props}
      >
        <StyledSnackbarContent severity={severity}>
          <FlexRow justifyContent='space-between' width='100%'>
            <Typography variant='bodyBoldMd'>{props.message}</Typography>
            <IconButton variant='text' color='secondary' size='medium' onClick={onClose}>
              <SvgIcon iconName='cross' />
            </IconButton>
          </FlexRow>
          {description && (
            <Typography variant='bodySm' color={tokens.colors.secondary.gray[800]} whiteSpace='pre-wrap'>
              {description}
            </Typography>
          )}
          {action && <action.type {...action.props} onClick={handleActionClick} />}
        </StyledSnackbarContent>
      </StyledSnackbar>
    </ThemeProvider>
  );
});

Snackbar.displayName = 'Snackbar';

const StyledSnackbar = styled(MuiSnackbar, {
  name: 'Snackbar',
})({
  position: 'static',
});

interface StyledSnackbarContentProps {
  severity: Exclude<SnackbarProps['severity'], undefined>;
}

const mapSeverityToBorderColor: Record<StyledSnackbarContentProps['severity'], string> = {
  default: tokens.colors.primary.light,
  info: tokens.colors.tertiary.blue[700],
  success: tokens.colors.tertiary.green[700],
  warning: tokens.colors.tertiary.amber[600],
  error: tokens.colors.tertiary.red[500],
};

const StyledSnackbarContent = styled(FlexColumn, {
  name: 'SnackbarContent',
  shouldForwardProp: (propName) => propName !== 'severity',
})<StyledSnackbarContentProps>(({ severity }) => ({
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: tokens.spacing[2],
  width: '400px',
  padding: `${tokens.spacing[3]} ${tokens.spacing[4]} ${tokens.spacing[3]} ${tokens.spacing[6]}`,
  backgroundColor: tokens.colors.primary.white,
  borderRadius: tokens.borderRadius[0.5],
  borderLeft: `2px solid ${mapSeverityToBorderColor[severity]}`,
  boxShadow: tokens.shadows[1],
}));
