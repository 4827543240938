import {
  DialogActions,
  DialogContent,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  ThemeProvider,
  alpha,
  styled,
} from '@mui/material';
import { ReactNode, Ref, memo, useCallback } from 'react';
import { theme as oldTheme } from 'src/theme/theme';
import { FlexColumn } from 'src/components/styled/styled';
import { Typography } from '../Typography/Typography';
import { theme } from '../assets/theme';
import { tokens } from '../assets/tokens/tokens';
import { mapSeverityToColor } from './util/constants';
import { DialogSeverity } from './util/types';

export type DialogProps = Omit<MuiDialogProps, 'fullWidth' | 'maxWidth'> & {
  disableBackdropClick?: boolean;
  title?: string;
  subtitle?: string;
  severity?: DialogSeverity;
  actions: Array<ReactNode>;
  isScrollable?: boolean;
  fullHeight?: boolean;
  size?: MuiDialogProps['maxWidth'];
  showActionsBorderTop?: boolean;
  contentRef?: Ref<HTMLDivElement>;
};

export const Dialog = memo<DialogProps>(
  ({
    children,
    title,
    subtitle,
    severity = 'default',
    actions,
    isScrollable = false,
    size = 'sm',
    showActionsBorderTop = false,
    contentRef,
    ...props
  }) => {
    const { onClose, disableBackdropClick } = props;
    const handleClose: DialogProps['onClose'] = useCallback(
      (event: object, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (disableBackdropClick && reason === 'backdropClick') return;
        onClose?.(event, reason);
      },
      [disableBackdropClick, onClose],
    );

    return (
      <ThemeProvider theme={theme}>
        <StyledDialog fullWidth maxWidth={size} {...props} onClose={handleClose}>
          <StyledDialogTitle severity={severity}>
            <Typography variant='h4'>{title}</Typography>
            {subtitle && (
              <Typography variant='bodySm' color={tokens.colors.secondary.gray[800]}>
                {subtitle}
              </Typography>
            )}
          </StyledDialogTitle>
          <StyledDialogContent ref={contentRef} isScrollable={isScrollable}>
            <ThemeProvider theme={oldTheme}>{children}</ThemeProvider>
          </StyledDialogContent>
          {actions && (
            <StyledDialogActions disableSpacing withBorderTop={showActionsBorderTop}>
              {actions}
            </StyledDialogActions>
          )}
        </StyledDialog>
      </ThemeProvider>
    );
  },
);

Dialog.displayName = 'Dialog';

interface StyledDialogProps {
  fullHeight?: boolean;
}
const StyledDialog = styled(MuiDialog, {
  name: 'Dialog',
  shouldForwardProp: (prop) => prop !== 'fullHeight',
})<StyledDialogProps>(({ fullHeight }) => ({
  '& .MuiDialog-paper': {
    borderRadius: `0 0 ${tokens.borderRadius[1]} ${tokens.borderRadius[1]}`,
    boxShadow: tokens.shadows[1],

    ...(fullHeight && {
      height: '100%',
      maxHeight: `calc(100vh - ${tokens.spacing[16]} - ${tokens.spacing[8]})`,
      margin: `${tokens.spacing[16]} ${tokens.spacing[8]} ${tokens.spacing[8]} ${tokens.spacing[8]}`,
    }),
  },

  '& .MuiModal-backdrop': {
    backgroundColor: alpha(tokens.colors.primary.dark, 0.1),
  },
}));

interface StyledDialogTitleProps {
  severity: DialogSeverity;
}

const StyledDialogTitle = styled(FlexColumn, {
  name: 'Dialog',
  slot: 'title',
  shouldForwardProp: (prop) => prop !== 'severity',
})<StyledDialogTitleProps>(({ severity }) => ({
  borderTop: `${tokens.spacing[1]} solid ${mapSeverityToColor[severity]}`,
  padding: `${tokens.spacing[4]} ${tokens.spacing[5]} ${tokens.spacing[3]}`,
  alignItems: 'flex-start',
  gap: tokens.spacing[0.5],
}));

interface StyledDialogContentProps {
  isScrollable: boolean;
}

const StyledDialogContent = styled(DialogContent, {
  name: 'Dialog',
  slot: 'content',
  shouldForwardProp: (prop) => prop !== 'isScrollable',
})<StyledDialogContentProps>(({ isScrollable }) => ({
  padding: isScrollable
    ? `${tokens.spacing[1]} ${tokens.spacing[3]} ${tokens.spacing[1]} ${tokens.spacing[5]}`
    : `${tokens.spacing[1]} ${tokens.spacing[5]}`,
  scrollbarGutter: isScrollable ? 'stable' : 'unset',
}));

interface StyledDialogActionsProps {
  withBorderTop: boolean;
}

const StyledDialogActions = styled(DialogActions, {
  name: 'Dialog',
  slot: 'actions',
  shouldForwardProp: (prop) => prop !== 'withBorderTop',
})<StyledDialogActionsProps>(({ withBorderTop }) => ({
  padding: `${tokens.spacing[4]} ${tokens.spacing[5]}`,
  gap: tokens.spacing[4],
  borderTop: withBorderTop ? `1px solid ${tokens.colors.secondary.gray[200]}` : 'none',
}));
