import { Button as MuiButton, ThemeProvider, styled } from '@mui/material';
import { forwardRef, memo, useEffect } from 'react';
import { utilityClassnames } from 'src/util/utilityClassnames';
import { clx } from 'src/util/classnames';
import { theme } from '../assets/theme';
import { tokens } from '../assets/tokens/tokens';
import { validateProps } from './util/validateProps';
import { ButtonProps } from './util/types';

export const Button = memo(
  forwardRef<HTMLButtonElement, ButtonProps>(
    ({ variant = 'contained', size = 'large', color = 'primary', anchor, ...props }, ref) => {
      useEffect(() => {
        validateProps({ variant, color, size });
      }, [variant, color, size]);

      return (
        <ThemeProvider theme={theme}>
          <StyledButton
            disableRipple
            disableElevation
            ref={ref}
            variant={variant}
            size={size}
            color={color}
            {...props}
            className={clx(props.className, { 'Mui-expanded': anchor })}
          />
        </ThemeProvider>
      );
    },
  ),
);

Button.displayName = 'Button';

const StyledButton = styled(MuiButton, {
  name: 'Button',
})({
  ...tokens.typography.bodyBoldMd,
  textTransform: 'none',
  letterSpacing: '0px',
  borderRadius: tokens.borderRadius[1],
  boxShadow: 'none',
  border: 'none',
  flex: '0 0 auto',

  '&.MuiButton-sizeSmall': {
    minWidth: '72px',
    height: '24px',
    padding: 'unset',
    gap: tokens.spacing[1],
  },

  '&.MuiButton-sizeLarge, &.MuiButton-sizeMedium': {
    minWidth: '72px',
    height: '32px',
    padding: `${tokens.spacing[1]} ${tokens.spacing[3]}`,
    gap: tokens.spacing[1],
  },

  '& .MuiButton-startIcon, & .MuiButton-endIcon': {
    margin: 'unset',
    color: 'inherit',
  },

  '&.Mui-focusVisible': {
    ':after': {
      content: '""',
      position: 'absolute',
      top: `-3px`,
      right: `-3px`,
      bottom: `-3px`,
      left: `-3px`,
      border: `1px solid ${tokens.colors.tertiary.blue[500]}`,
      borderRadius: tokens.borderRadius[1.5],
    },
  },

  '&.MuiButton-contained': {
    '&.MuiButton-containedPrimary': {
      background: tokens.colors.primary.light,
      color: tokens.colors.primary.white,

      [`&:hover, &.${utilityClassnames.hover}`]: {
        background: tokens.colors.secondary.purple[600],
        color: tokens.colors.primary.white,
      },

      '&.Mui-expanded': {
        background: tokens.colors.secondary.purple[600],
        color: tokens.colors.primary.white,
      },

      [`&:active, &.${utilityClassnames.active}`]: {
        background: tokens.colors.secondary.purple[700],
        color: tokens.colors.primary.white,
      },

      '&:disabled, &.Mui-disabled': {
        background: tokens.colors.secondary.gray[30050],
        color: tokens.colors.secondary.gray[800],
      },
    },

    '&.MuiButton-containedError': {
      background: tokens.colors.tertiary.red[400],
      color: tokens.colors.primary.white,

      [`&:hover, &.${utilityClassnames.hover}`]: {
        background: tokens.colors.tertiary.red[600],
        color: tokens.colors.primary.white,
      },

      '&.Mui-expanded': {
        background: tokens.colors.tertiary.red[600],
        color: tokens.colors.primary.white,
      },

      [`&:active, &.${utilityClassnames.active}`]: {
        background: tokens.colors.tertiary.red[700],
        color: tokens.colors.primary.white,
      },

      '&:disabled, &.Mui-disabled': {
        background: tokens.colors.secondary.gray[30050],
        color: tokens.colors.secondary.gray[800],
      },
    },
  },

  '&.MuiButton-outlined': {
    '&.MuiButton-outlinedPrimary': {
      border: `1px solid ${tokens.colors.secondary.gray[600]}`,
      background: 'transparent',
      color: tokens.colors.primary.dark,

      [`&:hover, &.${utilityClassnames.hover}`]: {
        border: `1px solid ${tokens.colors.secondary.gray[600]}`,
        background: tokens.colors.secondary.purple[20040],
        color: tokens.colors.primary.dark,
      },

      '&.Mui-expanded': {
        border: `1px solid ${tokens.colors.secondary.gray[600]}`,
        background: tokens.colors.secondary.purple[20040],
        color: tokens.colors.primary.dark,
      },

      [`&:active, &.${utilityClassnames.active}`]: {
        border: `1px solid ${tokens.colors.secondary.gray[600]}`,
        background: tokens.colors.secondary.purple[30050],
        color: tokens.colors.primary.dark,
      },

      '&:disabled, &.Mui-disabled': {
        border: `1px solid ${tokens.colors.secondary.gray[400]}`,
        background: 'transparent',
        color: tokens.colors.secondary.gray[600],
      },
    },

    '&.MuiButton-outlinedSecondary': {
      border: `1px solid ${tokens.colors.secondary.gray[300]}`,
      background: 'transparent',
      color: tokens.colors.secondary.gray[800],

      [`&:hover, &.${utilityClassnames.hover}`]: {
        border: `1px solid ${tokens.colors.secondary.gray[300]}`,
        background: tokens.colors.secondary.gray[20040],
        color: tokens.colors.secondary.gray[900],
      },

      '&.Mui-expanded': {
        border: `1px solid ${tokens.colors.secondary.gray[300]}`,
        background: tokens.colors.secondary.gray[20040],
        color: tokens.colors.secondary.gray[900],
      },

      [`&:active, &.${utilityClassnames.active}`]: {
        border: `1px solid ${tokens.colors.secondary.gray[300]}`,
        background: tokens.colors.secondary.gray[30050],
        color: tokens.colors.secondary.gray[900],
      },

      '&:disabled, &.Mui-disabled': {
        border: `1px solid ${tokens.colors.secondary.gray[30050]}`,
        background: 'transparent',
        color: tokens.colors.secondary.gray[500],
      },
    },

    '&.MuiButton-outlinedError': {
      border: `1px solid ${tokens.colors.tertiary.red[400]}`,
      background: 'transparent',
      color: tokens.colors.tertiary.red[400],

      [`&:hover, &.${utilityClassnames.hover}`]: {
        border: `1px solid ${tokens.colors.tertiary.red[400]}`,
        background: tokens.colors.tertiary.red[100],
        color: tokens.colors.tertiary.red[400],
      },

      '&.Mui-expanded': {
        border: `1px solid ${tokens.colors.tertiary.red[400]}`,
        background: tokens.colors.tertiary.red[100],
        color: tokens.colors.tertiary.red[400],
      },

      [`&:active, &.${utilityClassnames.active}`]: {
        border: `1px solid ${tokens.colors.tertiary.red[400]}`,
        background: tokens.colors.tertiary.red[200],
        color: tokens.colors.tertiary.red[400],
      },

      '&:disabled, &.Mui-disabled': {
        border: `1px solid ${tokens.colors.secondary.gray[400]}`,
        background: 'transparent',
        color: tokens.colors.secondary.gray[600],
      },
    },
  },

  '&.MuiButton-text': {
    '&.MuiButton-textPrimary': {
      background: 'transparent',
      color: tokens.colors.primary.light,

      [`&:hover, &.${utilityClassnames.hover}`]: {
        background: tokens.colors.secondary.purple[20040],
        color: tokens.colors.primary.light,
      },

      '&.Mui-expanded': {
        background: tokens.colors.secondary.purple[20040],
        color: tokens.colors.primary.light,
      },

      [`&:active, &.${utilityClassnames.active}`]: {
        background: tokens.colors.secondary.purple[30050],
        color: tokens.colors.secondary.purple[600],
      },

      '&:disabled, &.Mui-disabled': {
        background: tokens.colors.primary.white,
        color: tokens.colors.secondary.gray[600],
      },

      '&.MuiButton-sizeSmall': {
        background: 'transparent',
        color: tokens.colors.primary.light,

        [`&:hover, &.${utilityClassnames.hover}`]: {
          background: 'transparent',
          color: tokens.colors.secondary.purple[800],
        },

        '&.Mui-expanded': {
          background: 'transparent',
          color: tokens.colors.secondary.purple[800],
        },

        [`&:active, &.${utilityClassnames.active}`]: {
          background: 'transparent',
          color: tokens.colors.secondary.purple[900],
        },

        '&:disabled, &.Mui-disabled': {
          background: 'transparent',
          color: tokens.colors.secondary.gray[600],
        },
      },
    },

    '&.MuiButton-textSecondary': {
      background: 'transparent',
      color: tokens.colors.secondary.gray[800],

      [`&:hover, &.${utilityClassnames.hover}`]: {
        background: tokens.colors.secondary.gray[20040],
        color: tokens.colors.secondary.gray[900],
      },

      '&.Mui-expanded': {
        background: tokens.colors.secondary.gray[20040],
        color: tokens.colors.secondary.gray[900],
      },

      [`&:active, &.${utilityClassnames.active}`]: {
        background: tokens.colors.secondary.gray[30050],
        color: tokens.colors.secondary.gray[900],
      },

      '&:disabled, &.Mui-disabled': {
        background: tokens.colors.primary.white,
        color: tokens.colors.secondary.gray[500],
      },
    },

    '&.MuiButton-textError': {
      background: 'transparent',
      color: tokens.colors.tertiary.red[400],

      [`&:hover, &.${utilityClassnames.hover}`]: {
        background: tokens.colors.tertiary.red[100],
        color: tokens.colors.tertiary.red[400],
      },

      '&.Mui-expanded': {
        background: tokens.colors.tertiary.red[100],
        color: tokens.colors.tertiary.red[400],
      },

      [`&:active, &.${utilityClassnames.active}`]: {
        background: tokens.colors.tertiary.red[200],
        color: tokens.colors.tertiary.red[600],
      },

      '&:disabled, &.Mui-disabled': {
        background: tokens.colors.primary.white,
        color: tokens.colors.secondary.gray[600],
      },
    },
  },
});
