import { Divider as MuiDivider, styled } from '@mui/material';
import { CSSProperties } from 'react';
import { tokens } from '../assets/tokens/tokens';

class FlexContainerClass {
  alignItems?: CSSProperties['alignItems'];
  justifyContent?: CSSProperties['justifyContent'];
  gap?: CSSProperties['gap'];
  width?: CSSProperties['width'];
  minWidth?: CSSProperties['minWidth'];
  maxWidth?: CSSProperties['maxWidth'];
  height?: CSSProperties['height'];
  minHeight?: CSSProperties['minHeight'];
  maxHeight?: CSSProperties['maxHeight'];
  marginBottom?: CSSProperties['marginBottom'];
  noWrap?: boolean;
}

type FlexContainer = FlexContainerClass;

type FlexContainerPropsArray = Array<keyof FlexContainer>;
const FlexContainerProps: FlexContainerPropsArray = Object.keys(new FlexContainerClass()) as FlexContainerPropsArray;

export const FlexRow = styled('div', {
  shouldForwardProp: (propName) => !FlexContainerProps.includes(propName as keyof FlexContainer),
})<FlexContainer>(
  ({
    alignItems = 'center',
    justifyContent = 'center',
    gap,
    width = 'auto',
    minWidth,
    maxWidth,
    height = 'auto',
    minHeight,
    maxHeight,
    noWrap,
  }) => ({
    display: 'flex',
    alignItems,
    justifyContent,
    gap,
    width,
    minWidth,
    maxWidth,
    height,
    minHeight,
    maxHeight,

    ...(noWrap && {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
  }),
);

export const FlexColumn = styled('div', {
  shouldForwardProp: (propName) => !FlexContainerProps.includes(propName as keyof FlexContainer),
})<FlexContainer>(
  ({
    alignItems = 'center',
    justifyContent = 'center',
    gap,
    width = 'auto',
    minWidth,
    maxWidth,
    height = 'auto',
    minHeight,
    maxHeight,
    marginBottom,
    noWrap,
  }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems,
    justifyContent,
    gap,
    width,
    minWidth,
    maxWidth,
    height,
    minHeight,
    maxHeight,
    marginBottom,

    ...(noWrap && {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
  }),
);

export const StyledContentWrapper = styled('div', {
  name: 'AdminPortalContentWrapper',
})({
  width: '100%',
  height: '100%',
  overflow: 'auto',
  scrollBehavior: 'smooth',
  scrollbarGutter: 'stable',
});

export const StyledPortalContentContainer = styled('div', {
  name: 'AdminPortalContentContainer',
})(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: `${theme.customSpacing[32]} 0 0`,
}));

interface DividerProps {
  color?: string;
  height?: string;
}

export const Divider = styled(MuiDivider)<DividerProps>(({ color, height }) => ({
  borderColor: color,
  backgroundColor: color,
  height,
}));

export const StyledList = styled('ul')({
  margin: `${tokens.spacing[4]} ${tokens.spacing[1]} 0`,
  paddingLeft: tokens.spacing[5],
});

export const StyledListItem = styled('li')({
  ':not(:last-of-type)': {
    marginBottom: tokens.spacing[2],
  },
});

export const StyledTabLayout = styled(FlexColumn, {
  name: 'TabLayout',
})({
  alignItems: 'stretch',
  justifyContent: 'flex-start',
  gap: tokens.spacing[4],
  width: '100%',
  height: '100%',
  padding: `${tokens.spacing[4]} 0`,
});

interface StyledCarrierLogoProps {
  width?: string;
  height?: string;
}

export const StyledCarrierLogo = styled('img', {
  name: 'CarrierLogo',
})<StyledCarrierLogoProps>(({ width = '20px', height = '20px' }) => ({
  width,
  height,
  mixBlendMode: 'multiply',
}));
