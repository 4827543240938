import * as tokens from './design-tokens.json';

export const customSpacing = {
  4: tokens.global['spacing-1'].value,
  8: tokens.global['spacing-2'].value,
  12: tokens.global['spacing-3'].value,
  16: tokens.global['spacing-4'].value,
  20: tokens.global['spacing-5'].value,
  24: tokens.global['spacing-6'].value,
  32: tokens.global['spacing-7'].value,
  40: tokens.global['spacing-8'].value,
  48: tokens.global['spacing-9'].value,
  56: tokens.global['spacing-10'].value,
  64: tokens.global['spacing-11'].value,
  72: tokens.global['spacing-12'].value,
  80: tokens.global['spacing-13'].value,
  88: tokens.global['spacing-14'].value,
  96: tokens.global['spacing-15'].value,
  104: tokens.global['spacing-16'].value,
  112: tokens.global['spacing-17'].value,
  120: tokens.global['spacing-18'].value,
  128: tokens.global['spacing-19'].value,
} as const;

export const colors = {
  white: tokens.global.Primary.White.value,
  dark: tokens.global.Primary.Dark.value,
  light: tokens.global.Primary.Light.value,

  gray100: tokens.global.Secondary.Gray[100].value,
  gray200: tokens.global.Secondary.Gray[200].value,
  gray300: tokens.global.Secondary.Gray[300].value,
  gray400: tokens.global.Secondary.Gray[400].value,
  gray500: tokens.global.Secondary.Gray[500].value,
  gray600: tokens.global.Secondary.Gray[600].value,
  gray700: tokens.global.Secondary.Gray[700].value,
  gray800: tokens.global.Secondary.Gray[800].value,
  gray900: tokens.global.Secondary.Gray[900].value,

  purple100: tokens.global.Secondary.Purple[100].value,
  purple200: tokens.global.Secondary.Purple[200].value,
  purple300: tokens.global.Secondary.Purple[300].value,
  purple400: tokens.global.Secondary.Purple[400].value,
  purple500: tokens.global.Secondary.Purple[500].value,
  purple600: tokens.global.Secondary.Purple[600].value,
  purple700: tokens.global.Secondary.Purple[700].value,
  purple800: tokens.global.Secondary.Purple[800].value,
  purple900: tokens.global.Secondary.Purple[900].value,
  purple1000: tokens.global.Secondary.Purple[1000].value,

  blue100: tokens.global.Tertiary.Blue[100].value,
  blue200: tokens.global.Tertiary.Blue[200].value,
  blue300: tokens.global.Tertiary.Blue[300].value,
  blue400: tokens.global.Tertiary.Blue[400].value,
  blue500: tokens.global.Tertiary.Blue[500].value,
  blue600: tokens.global.Tertiary.Blue[600].value,
  blue700: tokens.global.Tertiary.Blue[700].value,
  blue800: tokens.global.Tertiary.Blue[800].value,
  blue900: tokens.global.Tertiary.Blue[900].value,

  red100: tokens.global.Tertiary.Red[100].value,
  red200: tokens.global.Tertiary.Red[200].value,
  red300: tokens.global.Tertiary.Red[300].value,
  red400: tokens.global.Tertiary.Red[400].value,
  red500: tokens.global.Tertiary.Red[500].value,
  red600: tokens.global.Tertiary.Red[600].value,
  red700: tokens.global.Tertiary.Red[700].value,
  red800: tokens.global.Tertiary.Red[800].value,
  red900: tokens.global.Tertiary.Red[900].value,

  green100: tokens.global.Tertiary.Green[100].value,
  green200: tokens.global.Tertiary.Green[200].value,
  green300: tokens.global.Tertiary.Green[300].value,
  green400: tokens.global.Tertiary.Green[400].value,
  green500: tokens.global.Tertiary.Green[500].value,
  green600: tokens.global.Tertiary.Green[600].value,
  green700: tokens.global.Tertiary.Green[700].value,
  green800: tokens.global.Tertiary.Green[800].value,
  green900: tokens.global.Tertiary.Green[900].value,

  amber100: tokens.global.Tertiary.Amber[100].value,
  amber200: tokens.global.Tertiary.Amber[200].value,
  amber300: tokens.global.Tertiary.Amber[300].value,
  amber400: tokens.global.Tertiary.Amber[400].value,
  amber500: tokens.global.Tertiary.Amber[500].value,
  amber600: tokens.global.Tertiary.Amber[600].value,
  amber700: tokens.global.Tertiary.Amber[700].value,
  amber800: tokens.global.Tertiary.Amber[800].value,
  amber900: tokens.global.Tertiary.Amber[900].value,

  teal100: tokens.global.Tertiary.Teal[100].value,
  teal200: tokens.global.Tertiary.Teal[200].value,
  teal300: tokens.global.Tertiary.Teal[300].value,
  teal400: tokens.global.Tertiary.Teal[400].value,
  teal500: tokens.global.Tertiary.Teal[500].value,
  teal600: tokens.global.Tertiary.Teal[600].value,
  teal700: tokens.global.Tertiary.Teal[700].value,
  teal800: tokens.global.Tertiary.Teal[800].value,
  teal900: tokens.global.Tertiary.Teal[900].value,

  accent1: tokens.global.Charts.Accent1.value,
  accent2: tokens.global.Charts.Accent2.value,
  accent3: tokens.global.Charts.Accent3.value,
  accent4: tokens.global.Charts.Accent4.value,
  accent5: tokens.global.Charts.Accent5.value,
  accent6: tokens.global.Charts.Accent6.value,
  accent7: tokens.global.Charts.Accent7.value,

  chartsGreen: tokens.global.Charts.Green.value,
  chartsBlue: tokens.global.Charts.Blue.value,
  chartsPurple: tokens.global.Charts.Purple.value,
  chartsRed: tokens.global.Charts.Red.value,
} as const;

export const typography = {
  a1: tokens.global.A['32 - A1'].value,
  a2: tokens.global.A['28 - A2'].value,
  a3: tokens.global.A['24 - A3'].value,
  a4: tokens.global.A['20 - A4'].value,
  a5: tokens.global.A['16 - A5'].value,
  a6: tokens.global.A['14 - A6'].value,
  a7: tokens.global.A['12 - A7'].value,

  h1: tokens.global.H['32 - H1'].value,
  h2: tokens.global.H['28 - H2'].value,
  h3: tokens.global.H['24 - H3'].value,
  h4: tokens.global.H['20 - H4'].value,
  h5: tokens.global.H['16 - H5'].value,
  h6: tokens.global.H['14 - H6'].value,
  h7: tokens.global.H['12 - H7'].value,

  i1: tokens.global.I['32 - I1'].value,
  i2: tokens.global.I['28 - I2'].value,
  i3: tokens.global.I['24 - I3'].value,
  i4: tokens.global.I['20 - I4'].value,
  i5: tokens.global.I['16 - I5'].value,
  i6: tokens.global.I['14 - I6'].value,
  i7: tokens.global.I['12 - I7'].value,
  i8: tokens.global.I['10 - I8'].value,

  body20xl: tokens.global.Body['20 - XL'].value,
  body16lg: tokens.global.Body['16 - LG'].value,
  body14md: tokens.global.Body['14 - MD'].value,
  body12sm: tokens.global.Body['12 - SM'].value,
  body10xs: tokens.global.Body['10 - XS'].value,

  bodyB20xl: tokens.global['Body B']['20 - XL'].value,
  bodyB16lg: tokens.global['Body B']['16 - LG'].value,
  bodyB14md: tokens.global['Body B']['14 - MD'].value,
  bodyB12sm: tokens.global['Body B']['12 - SM'].value,
  bodyB10xs: tokens.global['Body B']['10 - XS'].value,

  bodyExtraB14md: tokens.global['Body Extra B']['14 - MD'].value,
  bodyItalic12sm: tokens.global['Body Italic']['12 - SM'].value,
} as const;

export const borderRadius = {
  4: tokens.global['border-radius-4'].value,
  6: tokens.global['border-radius-6'].value,
  8: tokens.global['border-radius-8'].value,
  12: tokens.global['border-radius-12'].value,
  16: tokens.global['border-radius-16'].value,
  20: tokens.global['border-radius-20'].value,
} as const;

const shadow1 = tokens.global['shadow-01'].value;
const shadow2 = tokens.global['shadow-02'].value;
const shadow2Error = tokens.global['shadow-02_error'].value;
const shadow3 = tokens.global['shadow-03'].value;
const shadow5 = tokens.global['shadow-05'].value;
const shadow6 = tokens.global['shadow-06'].value;

export const customShadows = {
  '1': `${shadow1.x}px ${shadow1.y}px ${shadow1.blur}px ${shadow1.spread}px ${shadow1.color}`,
  '2': `${shadow2.x}px ${shadow2.y}px ${shadow2.blur}px ${shadow2.spread}px ${shadow2.color}`,
  '2Error': `${shadow2Error.x}px ${shadow2Error.y}px ${shadow2Error.blur}px ${shadow2Error.spread}px 
  ${shadow2Error.color}`,
  '3': `${shadow3.x}px ${shadow3.y}px ${shadow3.blur}px ${shadow3.spread}px ${shadow3.color}`,
  '4': `0px 1px 1px 0px rgba(30, 26, 50, 0.01), 0px 4px 2px 0px rgba(30, 26, 50, 0.02), 
  0px 8px 6px 0px rgba(30, 26, 50, 0.02), 0px 28px 19px 0px rgba(30, 26, 50, 0.03)`,
  '5': `${shadow5.x}px ${shadow5.y}px ${shadow5.blur}px ${shadow5.spread}px ${shadow5.color}`,
  '6': `${shadow6.x}px ${shadow6.y}px ${shadow6.blur}px ${shadow6.spread}px ${shadow6.color}`,
} as const;
