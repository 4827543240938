import { memo, useCallback } from 'react';
import { Button, Container, Grid, styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { theme } from 'src/theme/theme';
import { FlexColumn } from 'src/components/styled/styled';
import { Logo } from 'src/components/Logo/Logo';

interface ErrorStateProps {
  title: string;
  description: string;
}

export const ErrorPage = memo<ErrorStateProps>(({ title, description }) => {
  const navigate = useNavigate();

  const handleBackClick = useCallback(() => {
    navigate(`/`);
    navigate(0);
  }, [navigate]);

  return (
    <StyledContainer>
      <Grid container justifyContent='center'>
        <Grid item xs={4} sm={6} md={6}>
          <FlexColumn gap={theme.customSpacing[40]} width='100%'>
            <Logo width='192px' height='62px' type='dark' />
            <FlexColumn gap={theme.customSpacing[8]}>
              <Typography variant='h1' color={theme.colors.dark}>
                {title}
              </Typography>
              <Typography color={theme.colors.gray800}>{description}</Typography>
            </FlexColumn>
            <Button onClick={handleBackClick}>Go back home</Button>
          </FlexColumn>
        </Grid>
      </Grid>
    </StyledContainer>
  );
});

ErrorPage.displayName = 'ErrorPage';

const StyledContainer = styled(Container)(() => ({
  display: 'flex',
  height: '100vh',
  alignItems: 'center',
}));
