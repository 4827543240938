import { ButtonProps } from './types';

export const validateProps = ({ variant, color, size }: ButtonProps) => {
  switch (variant) {
    case 'contained': {
      if (color !== 'primary' && color !== 'error') {
        throw Error('Contained button available only in primary or error colors');
      }

      if (size !== 'large' && size !== 'medium') {
        throw Error('Contained button available only in large and medium sizes');
      }

      break;
    }
    case 'outlined': {
      if (size !== 'large' && size !== 'medium') {
        throw Error('Outlined button available only in large and medium sizes');
      }

      break;
    }
    case 'text': {
      if (size === 'small' && color !== 'primary') {
        throw Error('Small text button available only in primary color');
      }

      break;
    }
  }
};
