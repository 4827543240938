import {
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
  ThemeProvider,
  styled,
} from '@mui/material';
import { ElementType, forwardRef, memo, useRef } from 'react';
import { useIsOverflowed } from 'src/hooks/useIsOverflowed';
import { mergeRefs } from 'src/util/mergeRefs';
import { Tooltip, TooltipProps } from '../Tooltip/Tooltip';
import { theme } from '../assets/theme';
import { tokens } from '../assets/tokens/tokens';

export type TypographyProps = Omit<MuiTypographyProps, 'variant' | 'color' | 'noWrap'> & {
  variant?: keyof typeof tokens.typography;
  color?: string;
  as?: ElementType;
} & (
    | {
        noWrap?: false;
        slotProps?: never;
      }
    | {
        noWrap: true;
        slotProps?: {
          tooltip: Omit<TooltipProps, 'children' | 'title' | 'onOpen'>;
        };
      }
  );

export const Typography = memo(
  forwardRef<HTMLSpanElement, TypographyProps>(
    ({ variant = 'bodyMd', color = 'inherit', slotProps, ...props }, ref) => {
      const innerRef = useRef<HTMLSpanElement | null>(null);
      const { isOverflowed, checkOverflow } = useIsOverflowed(innerRef, false);

      return (
        <ThemeProvider theme={theme}>
          {props.noWrap ? (
            <Tooltip
              title={isOverflowed ? props.children : ''}
              onOpen={checkOverflow}
              withoutWrapper
              {...slotProps?.tooltip}
            >
              <StyledTypography
                as={variantMapping[variant]}
                ref={mergeRefs(innerRef, ref)}
                font={variant}
                color={color}
                width='100%'
                {...props}
              />
            </Tooltip>
          ) : (
            <StyledTypography
              as={variantMapping[variant]}
              ref={mergeRefs(innerRef, ref)}
              font={variant}
              color={color}
              {...props}
            />
          )}
        </ThemeProvider>
      );
    },
  ),
);

Typography.displayName = 'Typography';

interface StyledTypographyProps {
  font: Exclude<TypographyProps['variant'], undefined>;
}

const StyledTypography = styled(MuiTypography, {
  shouldForwardProp: (prop) => prop !== 'font',
})<StyledTypographyProps>(({ font }) => ({
  ...tokens.typography[font],
}));

const variantMapping: Record<Exclude<TypographyProps['variant'], undefined>, ElementType> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  h7: 'p',
  h8: 'p',
  bodyXl: 'span',
  bodyLg: 'span',
  bodyMd: 'span',
  bodySm: 'span',
  bodyXs: 'span',
  bodyBoldXl: 'span',
  bodyBoldLg: 'span',
  bodyBoldMd: 'span',
  bodyBoldSm: 'span',
  bodyBoldXs: 'span',
  codeMd: 'span',
  presentationSemiBold72: 'span',
  presentationMedium44: 'span',
  presentationRegular44: 'span',
  presentationThin44: 'span',
  presentationMedium28: 'span',
  presentationRegular28: 'span',
  presentationMedium24: 'span',
  presentationRegular24: 'span',
  presentationSemiBold16: 'span',
  presentationMedium16: 'span',
  presentationRegular16: 'span',
  presentationSemiBold14: 'span',
  presentationMedium14: 'span',
  presentationRegular14: 'span',
  presentationItalic14: 'span',
  presentationMedium12: 'span',
  presentationRegular12: 'span',
  presentationItalic12: 'span',
};
