import { KeyboardEvent, memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/Button/Button';
import { Dialog } from 'src/components/Dialog/Dialog';

interface ReleaseDialogProps {
  open: boolean;
}

export const ReleaseDialog = memo<ReleaseDialogProps>(({ open }) => {
  const navigate = useNavigate();

  const handlePageReload = useCallback(() => {
    navigate(0);
  }, [navigate]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter' || event.key === 'Escape') {
        handlePageReload();
      }
    },
    [handlePageReload],
  );

  const dialogActions = useMemo(
    () => [
      <Button key='reload' onClick={handlePageReload}>
        Reload
      </Button>,
    ],
    [handlePageReload],
  );

  return (
    <Dialog open={open} title='Reload required' onKeyDown={handleKeyDown} actions={dialogActions}>
      We updated our platform. Please reload the page for better experience.
    </Dialog>
  );
});

ReleaseDialog.displayName = 'ReleaseDialog';
