import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ActivateUserRequest, LoginForm, UserActivationResponse, VerifyOTPRequest } from 'src/generatedApi';
import { GeneratedApiError } from 'src/types';
import {
  activateUser,
  getInvitationInfo,
  getUserInfo,
  loginUser,
  logoutUser,
  restorePassword,
  sendOTP,
  sendRestoreTokenForPassword,
  verifyOTP,
} from '../fetchers/UserAuthenticationService';
import { RequestKey } from './requestKey';

export const useLoginUser = () =>
  useMutation<void, GeneratedApiError, LoginForm>({
    mutationFn: loginUser,
  });

export const useLogoutUser = () => {
  const queryClient = useQueryClient();

  return useMutation<void, GeneratedApiError, void>({
    mutationFn: logoutUser,
    onSuccess: () => {
      queryClient.setQueryData([RequestKey.UserInfo], null);
    },
  });
};

export const useSendRestoreToken = () => useMutation({ mutationFn: sendRestoreTokenForPassword });
export const useRestorePassword = () => useMutation({ mutationFn: restorePassword });
export const useGetUserInfo = () =>
  useQuery({
    queryKey: [RequestKey.UserInfo],
    queryFn: getUserInfo,
    placeholderData: keepPreviousData,
  });

export const useVerifyOTP = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, GeneratedApiError, VerifyOTPRequest>({
    mutationFn: verifyOTP,
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: [RequestKey.UserInfo],
      }),
  });
};

export const useSendOTP = () => useMutation({ mutationFn: sendOTP });

export const useGetInvitationInfo = (invitationToken: string, enabled = true) =>
  useQuery<UserActivationResponse, GeneratedApiError>({
    queryKey: [RequestKey.ClientInvitationInfo, invitationToken],
    queryFn: () => getInvitationInfo(invitationToken),
    enabled,
    retry: false,
  });

export const useActivateUser = () =>
  useMutation<unknown, GeneratedApiError, ActivateUserRequest>({
    mutationFn: activateUser,
  });
