import { memo } from 'react';
import { ErrorPage } from 'src/components/ErrorPage/ErrorPage';

export const ErrorNotFoundPage = memo(() => (
  <ErrorPage
    title='404. Page not found'
    description='The page you are looking for was not found, perhaps its name or link to it has changed'
  />
));

ErrorNotFoundPage.displayName = 'ErrorNotFoundPage';
