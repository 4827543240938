export enum Paths {
  ERROR_RUNTIME = 'error',
  ERROR_NOT_FOUND = 'not-found',
  ERROR_ACCESS_DENIED = 'access-denied',
  ERROR_BROWSER_VERSION = 'upgrade-required',
  SURVEY = 'survey',
  SIGNIN = 'signin',
  JOIN = 'join',
  OTP = 'otp',
  ADMIN = 'admin',
  DASHBOARDS = 'dashboards',
  CLIENTS = 'clients',
  CREATE_NEW = 'new',
  RESET_PASSWORD = 'reset-password',
  CALCULATOR = 'calculator',
  ILLUSTRATIONS = 'illustrations',
  CARRIERS = 'carriers',
  REPORTS = 'reports',
  DOCUMENTATION = 'documentation',
  MARKETING = 'marketing',
  CLIENTS_INFORMATION_REPORT = 'clients-information',
  AGENT = 'agent',
  AGENTS = 'agents',
  PRODUCTS_COMPARISON_EXPORT = 'products-comparison-export',
  PRODUCT_VS_TRADITIONAL_COMPARISON_EXPORT = 'product-vs-traditional-comparison-export',
  DISCOVERY_MATRIX_EXPORT = 'discovery-matrix-export',
}
