import { alpha, createTheme, menuClasses } from '@mui/material';
import { createBreakpoints } from '@mui/system';
import { LinkProps } from '@mui/material/Link';
import IconX from '../assets/icons/x.svg';
import IconRadioButtonChecked from '../assets/icons/radio-button-checked.svg';
import IconRadioButton from '../assets/icons/radio-button.svg';
import { borderRadius, colors, customShadows, customSpacing, typography } from './themeElements';
import { LinkBehavior } from './link';

export const commonFontStyles = {
  ...typography.body14md,
  color: colors.dark,
} as const;

const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1280,
    xl: 1600,
  },
});

export const theme = createTheme({
  typography,
  colors,
  customSpacing,
  borderRadius,
  customShadows,
  breakpoints,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: '100%',
        },
        body: {
          height: '100%',
          ...commonFontStyles,
          backgroundColor: colors.white,
          overflow: 'hidden',
        },
        '#root': {
          height: '100%',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiGrid: {
      defaultProps: {
        columns: { xs: 4, sm: 8, md: 12, lg: 12 },
        spacing: { xs: '16px', sm: '24px' },
      },
    },
    MuiTypography: {
      defaultProps: {
        variant: 'body14md',
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        type: 'button',
        variant: 'solid',
        size: 'medium',
        color: 'primary',
      },
      styleOverrides: {
        root: {
          flexShrink: 0,
          gap: customSpacing[8],
          minWidth: 'unset',
          padding: `0 ${customSpacing[12]}`,
          borderRadius: borderRadius[4],
          ...typography.body14md,
          textTransform: 'none',

          ':disabled': {
            color: colors.gray600,
            ' svg path:first-of-type': {
              fill: colors.gray600,
            },
          },
        },
        sizeMedium: {
          height: '40px',
        },
        sizeSmall: {
          height: '32px',
        },
        startIcon: {
          margin: 'unset',
          visibility: 'visible',
        },
        endIcon: {
          margin: 'unset',
          visibility: 'visible',
        },
      },
      variants: [
        {
          props: { variant: 'solid' },
          style: {
            minWidth: '96px',
            backgroundColor: colors.light,
            color: colors.white,

            '&:hover:not(.Mui-disabled, &:active)': {
              backgroundColor: colors.purple600,
            },

            '&:active': {
              backgroundColor: colors.purple800,
            },

            '&:disabled, &.Mui-disabled': {
              backgroundColor: colors.gray300,
            },
          },
        },
        {
          props: { variant: 'solid', color: 'error' },
          style: {
            backgroundColor: colors.red400,

            '&:hover:not(.Mui-disabled, &:active)': {
              backgroundColor: colors.red600,
            },

            '&:active': {
              backgroundColor: colors.red800,
            },

            '&:disabled, &.Mui-disabled': {
              backgroundColor: colors.gray300,
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            minWidth: '96px',
            border: 'none',
            backgroundColor: 'transparent',
            color: colors.dark,

            '&:hover:not(.Mui-disabled, &:active)': {
              backgroundColor: colors.gray200,
            },

            ':active': {
              backgroundColor: colors.purple100,
            },

            ':disabled, &.Mui-disabled': {
              backgroundColor: 'transparent',
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            border: `1px solid ${colors.light}`,

            '&:hover:not(.Mui-disabled, &:active)': {
              borderColor: colors.purple600,
            },

            ':active': {
              borderColor: colors.purple600,
            },

            ':disabled, &.Mui-disabled': {
              borderColor: colors.gray300,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            border: `1px solid ${colors.gray300}`,

            '&:hover:not(.Mui-disabled, &:active)': {
              borderColor: colors.gray300,
            },

            ':active': {
              borderColor: colors.gray300,
            },

            ':disabled, &.Mui-disabled': {
              borderColor: colors.gray300,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'error' },
          style: {
            border: `1px solid ${colors.red400}`,
            color: colors.red400,

            '&:hover:not(.Mui-disabled, &:active)': {
              borderColor: colors.red500,
              backgroundColor: colors.red100,
            },

            ':active': {
              borderColor: colors.red400,
              backgroundColor: colors.red200,
            },

            ':disabled, &.Mui-disabled': {
              borderColor: colors.gray300,
            },
          },
        },
        {
          props: { variant: 'subtle' },
          style: {
            minWidth: '96px',
            border: 'none',
            backgroundColor: 'transparent',

            '&:hover:not(.Mui-disabled, &:active)': {
              backgroundColor: colors.gray200,
            },

            '&:active': {
              backgroundColor: colors.purple100,
            },

            '&:disabled, &.Mui-disabled': {
              backgroundColor: 'transparent',
            },
          },
        },
        {
          props: { variant: 'subtle', color: 'primary' },
          style: {
            color: colors.light,
          },
        },
        {
          props: { variant: 'subtle', color: 'secondary' },
          style: {
            color: colors.gray900,

            '&:hover:not(.Mui-disabled, &:active)': {
              color: colors.dark,
            },

            '&:active': {
              color: colors.dark,
            },
          },
        },
        {
          props: { variant: 'subtle', color: 'error' },
          style: {
            color: colors.red400,

            '&:hover:not(.Mui-disabled, &:active)': {
              backgroundColor: colors.red100,
              color: colors.red600,
            },

            '&:active': {
              backgroundColor: colors.red200,
              color: colors.red400,
            },
          },
        },
        {
          props: { variant: 'actionIconSolid' },
          style: {
            padding: 'unset',
            backgroundColor: colors.light,
            color: colors.white,

            '&:hover:not(.Mui-disabled, &:active)': {
              backgroundColor: colors.purple600,
              color: colors.white,
            },

            '&:active': {
              backgroundColor: colors.purple800,
              color: colors.white,
            },

            '&:disabled, &.Mui-disabled': {
              backgroundColor: colors.gray300,
              color: colors.gray300,
            },
          },
        },
        {
          props: { variant: 'actionIconSolid', color: 'error' },
          style: {
            backgroundColor: colors.red400,

            '&:hover:not(.Mui-disabled, &:active)': {
              backgroundColor: colors.red600,
            },

            '&:active': {
              backgroundColor: colors.red800,
            },
          },
        },
        {
          props: { variant: 'actionIconSolid', size: 'medium' },
          style: {
            width: '40px',
            height: '40px',
          },
        },
        {
          props: { variant: 'actionIconSolid', size: 'small' },
          style: {
            width: '32px',
            height: '32px',
          },
        },
        {
          props: { variant: 'actionIconOutlined' },
          style: {
            padding: 'unset',
            backgroundColor: 'transparent',

            '&:hover:not(.Mui-disabled, &:active)': {
              backgroundColor: colors.gray200,
            },

            '&:active': {
              backgroundColor: colors.purple100,
            },

            '&:disabled, &.Mui-disabled': {
              borderColor: colors.gray300,
              color: colors.gray600,
            },
          },
        },
        {
          props: { variant: 'actionIconOutlined', color: 'primary' },
          style: {
            border: `1px solid ${colors.light}`,
            color: colors.light,

            '&:hover:not(.Mui-disabled, &:active)': {
              borderColor: colors.purple600,
              color: colors.purple600,
            },

            '&:active': {
              borderColor: colors.purple800,
              color: colors.purple800,
            },
          },
        },
        {
          props: { variant: 'actionIconOutlined', color: 'secondary' },
          style: {
            border: `1px solid ${colors.gray300}`,
            color: colors.gray900,

            '&:hover:not(.Mui-disabled, &:active)': {
              borderColor: colors.gray300,
              color: colors.dark,
            },

            '&:active': {
              borderColor: colors.gray300,
              color: colors.dark,
            },
          },
        },
        {
          props: { variant: 'actionIconOutlined', color: 'error' },
          style: {
            border: `1px solid ${colors.red400}`,
            color: colors.red400,

            '&:hover:not(.Mui-disabled, &:active)': {
              backgroundColor: colors.red100,
            },

            '&:active': {
              backgroundColor: colors.red200,
            },
          },
        },
        {
          props: { variant: 'actionIconOutlined', size: 'medium' },
          style: {
            width: '40px',
            height: '40px',
          },
        },
        {
          props: { variant: 'actionIconOutlined', size: 'small' },
          style: {
            width: '32px',
            height: '32px',
          },
        },
        {
          props: { variant: 'actionIconSubtle' },
          style: {
            padding: 'unset',
            backgroundColor: 'transparent',
            border: 'none',

            '&:hover:not(.Mui-disabled, &:active)': {
              backgroundColor: colors.gray200,
            },

            '&:active': {
              backgroundColor: colors.purple100,
            },

            '&:disabled, &.Mui-disabled': {
              color: colors.gray600,
            },
          },
        },
        {
          props: { variant: 'actionIconSubtle', color: 'primary' },
          style: {
            color: colors.light,

            '&:hover:not(.Mui-disabled, &:active)': {
              color: colors.light,
            },

            '&:active': {
              color: colors.light,
            },

            '&:disabled, &.Mui-disabled': {
              color: colors.light,
            },
          },
        },
        {
          props: { variant: 'actionIconSubtle', color: 'secondary' },
          style: {
            color: colors.gray900,

            '&:hover:not(.Mui-disabled, &:active)': {
              color: colors.dark,
            },

            '&:active': {
              color: colors.dark,
            },
          },
        },
        {
          props: { variant: 'actionIconSubtle', color: 'error' },
          style: {
            color: colors.red400,

            '&:hover:not(.Mui-disabled, &:active)': {
              backgroundColor: colors.red100,
            },

            '&:active': {
              backgroundColor: colors.red200,
            },

            '&:disabled, &.Mui-disabled': {
              color: colors.gray600,
            },
          },
        },
        {
          props: { variant: 'actionIconSubtle', size: 'medium' },
          style: {
            width: '40px',
            height: '40px',
          },
        },
        {
          props: { variant: 'actionIconSubtle', size: 'small' },
          style: {
            width: '32px',
            height: '32px',
          },
        },
        {
          props: { variant: 'file' },
          style: {
            backgroundColor: colors.purple100,
            color: colors.dark,

            '&:hover:not(.Mui-disabled, &:active)': {
              backgroundColor: colors.purple200,
            },

            '&:active': {
              backgroundColor: colors.purple300,
            },

            '&:disabled, &.Mui-disabled': {
              backgroundColor: colors.gray100,
              color: colors.gray600,
            },
          },
        },
      ],
    },
    MuiButtonGroup: {
      defaultProps: { disableRipple: true },
      styleOverrides: {
        grouped: {
          minWidth: 'unset',

          '&:not(:first-of-type)': {
            margin: 'unset',
          },

          '&:not(:last-of-type)': {
            borderRight: `1px solid ${colors.purple700}`,

            '&:hover': {
              borderRight: `1px solid ${colors.purple700}`,
            },
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          height: '100%',
          margin: 'unset',
          ...commonFontStyles,
          color: colors.gray800,

          '& .MuiTypography-root': {
            ...commonFontStyles,
            color: colors.gray800,
          },

          '&.MuiInputAdornment-filled': {
            '&.MuiInputAdornment-positionStart': {
              ':not(.MuiInputAdornment-hiddenLabel)': {
                margin: 'unset',
              },
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          ...typography.body14md,
          color: colors.dark,
        },
        input: {
          ...typography.body14md,
          color: colors.dark,

          '&:autofill': {
            boxShadow: `inset 0 0 0 100px ${colors.white}`,
          },

          '&:-webkit-autofill': {
            borderRadius: 'unset',
          },

          '&.MuiFilledInput-input': {
            '&:autofill': {
              boxShadow: `inset 0 0 0 100px ${colors.gray100}`,
            },

            '&:-webkit-autofill': {
              borderRadius: 'unset',
            },
          },

          "&[type='password']": {
            fontFamily: 'Verdana',
            fontSize: '16px',
            letterSpacing: customSpacing[4],

            '::placeholder': {
              ...commonFontStyles,
            },
          },
          "&[type='number']": {
            '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
              appearance: 'none',
              margin: 0,
            },
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
        InputLabelProps: { shrink: true },
      },
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            gap: customSpacing[8],
            padding: `${customSpacing[8]} ${customSpacing[12]}`,
            borderRadius: borderRadius[4],
            ':not(.MuiInputBase-multiline, .autocomplete-input-multiple)': {
              height: '40px',
            },
          },

          '& .MuiInputBase-root fieldset': {
            display: `none`,
          },

          '& .MuiInputBase-input': {
            height: 'unset',
            padding: 'unset',
            backgroundColor: 'transparent',

            '&.Mui-disabled': {
              color: colors.gray600,
              WebkitTextFillColor: colors.gray600,
            },

            '&::placeholder': {
              color: colors.dark,
            },
          },
        },
      },
      variants: [
        {
          props: { variant: 'filled' },
          style: {
            '& .MuiInputBase-root': {
              backgroundColor: colors.gray100,
              border: `1px solid transparent`,

              '&:hover:not(.Mui-disabled, .Mui-focused)': {
                backgroundColor: colors.gray100,
                borderColor: colors.purple200,
              },

              '&.Mui-focused': {
                backgroundColor: colors.gray100,
                borderColor: colors.purple500,
              },

              '&.Mui-disabled': {
                backgroundColor: colors.gray200,
              },

              '&::after, &::before': {
                display: `none`,
              },
            },
          },
        },
        {
          props: { variant: 'filled', error: true },
          style: {
            '& .MuiInputBase-root': {
              border: `1px solid ${colors.red400}`,

              '&:hover:not(.Mui-disabled, .Mui-focused)': {
                backgroundColor: colors.gray100,
                borderColor: colors.red400,
              },

              '&.Mui-focused': {
                borderColor: colors.red600,
              },

              '&.Mui-disabled': {
                backgroundColor: colors.gray200,
              },
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            '& .MuiInputBase-root': {
              border: `1px solid ${colors.gray300}`,

              '&:hover:not(.Mui-disabled, .Mui-focused)': {
                borderColor: colors.gray800,
              },

              '&.Mui-focused': {
                borderColor: colors.light,
              },

              '&.Mui-disabled': {
                backgroundColor: colors.gray100,
                borderColor: colors.gray200,
              },
            },
          },
        },
        {
          props: { variant: 'outlined', error: true },
          style: {
            '& .MuiInputBase-root': {
              borderColor: colors.red400,

              '&:hover:not(.Mui-disabled, .Mui-focused)': {
                borderColor: colors.red600,
              },

              '&.Mui-focused': {
                borderColor: colors.red800,
              },

              '&.Mui-disabled': {
                backgroundColor: colors.gray100,
                borderColor: colors.red400,
              },
            },
          },
        },
        {
          props: { variant: 'standard' },
          style: {
            '& .MuiInputBase-root': {
              '&::before': {
                borderBottom: `1px solid ${colors.gray800}`,
              },

              '&:hover:not(.Mui-disabled, .Mui-focused)': {
                '&::before': {
                  borderBottom: `2px solid ${colors.gray800}`,
                },
              },

              '&::after': {
                borderBottom: `2px solid ${colors.light}`,
              },

              '&.Mui-disabled': {
                '&::before': {
                  borderColor: colors.gray600,
                },
              },
            },
          },
        },
        {
          props: { variant: 'standard', error: true },
          style: {
            '& .MuiInputBase-root': {
              '&::before': {
                borderBottom: `1px solid ${colors.red400}`,
              },

              '&:hover:not(.Mui-disabled, .Mui-focused)': {
                '&::before': {
                  borderBottom: `2px solid ${colors.red400}`,
                },
              },

              '&::after': {
                borderBottom: `2px solid ${colors.red800}`,
              },

              '&.Mui-disabled': {
                '&::before': {
                  borderColor: colors.red400,
                },
              },
            },
          },
        },
      ],
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            padding: `${customSpacing[8]} ${customSpacing[12]}`,
          },

          [`&.MuiAutocomplete-hasPopupIcon, 
          &.MuiAutocomplete-hasClearIcon, 
          &.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon`]: {
            '& .MuiAutocomplete-inputRoot': {
              padding: `${customSpacing[8]} ${customSpacing[12]}`,
            },
          },

          '& .MuiAutocomplete-popupIndicator': {
            padding: 'unset',
          },

          '& .MuiAutocomplete-clearIndicator': {
            padding: 'unset',
            marginRight: `${customSpacing[8]}`,
          },

          '& .MuiFilledInput-root, & .MuiOutlinedInput-root, & .MuiInput-root': {
            '& .MuiFilledInput-input, & .MuiOutlinedInput-input, & .MuiInput-input': {
              padding: 'unset',
            },

            '& .MuiAutocomplete-endAdornment': {
              position: 'unset',
              transform: 'unset',
            },
          },
        },
        popper: {
          boxShadow: customShadows[1],

          ' .MuiAutocomplete-option': {
            '&.Mui-focused': {
              backgroundColor: colors.gray100,
            },

            '&[aria-selected="true"], &[aria-selected="true"].Mui-focused': {
              backgroundColor: colors.gray200,
            },
          },
        },
        paper: {
          marginTop: customSpacing[4],
        },
        listbox: {
          maxHeight: '200px',
          padding: `${customSpacing[12]} 0`,
          borderRadius: borderRadius[4],

          ' .MuiAutocomplete-option': {
            '&.Mui-focused': {
              backgroundColor: colors.gray100,
            },

            '&[aria-selected="true"], &[aria-selected="true"].Mui-focused': {
              backgroundColor: colors.gray200,
            },
          },
        },
        option: {
          ...commonFontStyles,
          color: colors.dark,
          marginBottom: `${customSpacing[4]}`,
          padding: `${customSpacing[8]} ${customSpacing[12]}`,
        },
        noOptions: {
          ...commonFontStyles,
          margin: `${customSpacing[12]} 0`,
          padding: `${customSpacing[8]} ${customSpacing[12]}`,
        },
        tag: {
          position: 'static',
          margin: 'unset',
          gap: customSpacing[4],
        },
      },
    },
    MuiChip: {
      defaultProps: {
        size: 'small',
        color: 'default',
        variant: 'filled',
        deleteIcon: <IconX fill='currentColor' />,
      },
      styleOverrides: {
        root: {
          gap: customSpacing[8],
          borderRadius: borderRadius[4],
          ...typography.body14md,
          color: colors.dark,

          '&.Mui-disabled': {
            opacity: 0.5,
          },
        },
        icon: {
          margin: 'unset',
        },
        deleteIcon: {
          margin: 'unset',
          color: 'inherit',
          borderRadius: borderRadius[4],

          '&:hover': {
            color: 'inherit',
            backgroundColor: 'color-mix(in srgb, currentColor 10%, transparent)',
          },
        },
        label: {
          padding: 'unset',
        },
        sizeSmall: {
          height: '24px',
          padding: `0 ${customSpacing[8]}`,

          '& .MuiChip-deleteIcon': {
            width: '16px',
            height: '16px',
          },
        },
        sizeMedium: {
          height: 'auto',
          padding: `${customSpacing[4]} ${customSpacing[12]}`,

          '& .MuiChip-deleteIcon': {
            width: '20px',
            height: '20px',
          },
        },
        outlined: {
          ':hover, &.MuiChip-clickable:hover': {
            backgroundColor: colors.gray200,
          },
        },
        colorInfo: {
          backgroundColor: colors.blue100,
          color: colors.blue900,
        },
        colorSuccess: {
          backgroundColor: colors.green100,
          color: colors.green900,
        },
        colorPrimary: {
          backgroundColor: colors.purple100,
          color: colors.light,
        },
        colorError: {
          backgroundColor: colors.red100,
          color: colors.red900,
        },
        colorWarning: {
          backgroundColor: colors.amber100,
          color: colors.amber900,
        },
      },
      variants: [
        {
          props: { variant: 'outlined', color: 'default' },
          style: {
            color: colors.dark,
            border: `1px solid ${colors.gray300}`,
          },
        },
        {
          props: { variant: 'filled', color: 'default' },
          style: {
            backgroundColor: colors.gray200,
          },
        },
        {
          props: { variant: 'filled', color: 'info' },
          style: {
            backgroundColor: colors.blue100,
          },
        },
        {
          props: { variant: 'filled', color: 'success' },
          style: {
            backgroundColor: colors.green100,
          },
        },
        {
          props: { variant: 'filled', color: 'error' },
          style: {
            backgroundColor: colors.red100,
          },
        },
      ],
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root': {
            transform: 'translate(0px, 0px)',
            marginBottom: '2px',

            '& .Mui-focused': {
              color: colors.dark,
            },
          },

          '& .MuiFormLabel-root.MuiInputLabel-root': {
            position: 'relative',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          ...typography.body12sm,
          color: colors.gray800,
          margin: '2px 0 0 0',

          '&.Mui-error': {
            ...commonFontStyles,
            fontSize: typography.body12sm,
            marginLeft: 'unset',
            color: colors.red400,
          },
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            '&.Mui-error': {
              color: colors.red400,
            },
          },
        },
        {
          props: { variant: 'standard' },
          style: {
            '&.Mui-error': {
              color: colors.red400,
            },
          },
        },
      ],
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          marginBottom: '2px',
          ...commonFontStyles,
          color: colors.dark,
          whiteSpace: 'normal',

          '&.MuiInputLabel-root': {
            whiteSpace: 'normal',
          },

          '& + .MuiInputBase-root': {
            marginTop: 'unset',
          },

          '&.Mui-focused': {
            color: colors.dark,
          },

          '&.Mui-error': {
            color: colors.dark,
          },

          '& h6': {
            display: 'inline',
          },
        },
        asterisk: {
          color: colors.red400,

          '&.Mui-error': {
            color: colors.red400,
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          padding: 'unset',

          '&.Mui-disabled:not(.MuiCheckbox-indeterminate)': {
            '&.Mui-checked': {
              '& svg rect': {
                fill: colors.gray800,
              },
            },
          },
        },
        indeterminate: {
          '& svg rect:nth-of-type(1)': {
            fill: colors.gray100,
          },

          '& svg path': {
            stroke: colors.light,
          },

          '&.Mui-checked': {
            '& svg rect:nth-of-type(1)': {
              fill: colors.purple600,
            },
            '& svg path': {
              stroke: colors.white,
            },
          },

          '&.Mui-disabled': {
            '& svg rect:nth-of-type(1)': {
              fill: colors.gray200,
            },
          },
        },
      },
    },
    MuiSwitch: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          width: '36px',
          height: '20px',
          padding: 'unset',
        },
        switchBase: {
          width: '100%',
          height: '100%',
          padding: '2px',
          justifyContent: 'flex-start',
          transitionDuration: '300ms',

          ':hover': {
            backgroundColor: 'unset',
          },

          '&.Mui-checked': {
            transform: 'translateX(16px)',

            '& + .MuiSwitch-track': {
              backgroundColor: colors.light,
              opacity: 1,
            },
          },
          '&.Mui-disabled': {
            '& + .MuiSwitch-track': {
              backgroundColor: colors.gray200,
              opacity: 1,
            },
            '&.Mui-checked + .MuiSwitch-track': {
              backgroundColor: colors.gray800,
              opacity: 1,
            },
          },
        },
        input: {
          height: '100%',
          width: '100%',
          left: 'unset',
        },
        thumb: {
          width: '16px',
          height: '16px',
          backgroundColor: colors.white,
          boxShadow: 'unset',
        },
        track: {
          borderRadius: borderRadius[12],
          backgroundColor: colors.gray300,
          opacity: 1,
          transitionDuration: '300ms',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: colors.gray300,
          '&.Mui-checked': {
            color: colors.light,
          },
          ':hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      defaultProps: {
        disableRipple: true,
        icon: <IconRadioButton />,
        checkedIcon: <IconRadioButtonChecked />,
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: colors.gray300,
          borderColor: colors.gray300,
        },
      },
      variants: [
        {
          props: { orientation: 'vertical' },
          style: {
            width: '1px',
          },
        },
        {
          props: { orientation: 'horizontal' },
          style: {
            height: '1px',
          },
        },
      ],
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
        underline: 'hover',
      } as LinkProps,
      styleOverrides: {
        root: {
          color: colors.light,
          cursor: 'pointer',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          margin: 'unset',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          margin: 'unset',
          padding: 'unset',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          marginTop: customSpacing[4],
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        bar: {
          backgroundColor: colors.light,
        },
      },
    },
    MuiMobileStepper: {
      styleOverrides: {
        root: {
          width: '100%',
          margin: 'unset',
          padding: 'unset',
        },
        progress: {
          width: '100%',
          backgroundColor: colors.gray300,
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        hidden: {
          visibility: 'hidden',
          opacity: 0,
          pointerEvents: 'none',
          ariaHidden: true,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          background: alpha(colors.dark, 0.4),
        },
        paper: {
          borderRadius: borderRadius[8],
        },
        paperFullScreen: {
          borderRadius: 0,
          [breakpoints.down('sm')]: {
            padding: 0,
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: `${customSpacing[20]} ${customSpacing[32]} ${customSpacing[16]}`,
          color: colors.dark,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: `${customSpacing[16]} ${customSpacing[32]}`,
        },
        dividers: {
          borderTop: `1px solid ${colors.gray300}`,
          borderBottom: `1px solid ${colors.gray300}`,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: `${customSpacing[20]} ${customSpacing[32]}`,
          gap: customSpacing[16],

          '>:not(:first-of-type)': {
            margin: 'unset',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          gap: customSpacing[12],
          margin: 'unset',
          ...commonFontStyles,
          color: colors.dark,

          '&:hover:not(.MuiCheckbox-indeterminate, .Mui-disabled)': {
            '& .MuiCheckbox-root': {
              '& svg rect:nth-of-type(2)': {
                stroke: colors.gray800,
              },

              '&.Mui-checked': {
                '& svg rect:nth-of-type(1)': {
                  fill: colors.purple600,
                },
              },
            },
          },
        },
        label: {
          '&.Mui-disabled': {
            color: colors.gray800,
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          flexGrow: 1,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          tableLayout: 'fixed',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          ' .CopyToClipboard-button': {
            visibility: 'hidden',
            pointerEvents: 'none',
            ariaHidden: true,
          },

          ':hover': {
            backgroundColor: colors.gray100,

            ' .MuiTableCell-root div, .CopyToClipboard-button': {
              ' *': {
                visibility: 'visible',
                pointerEvents: 'auto',
                ariaHidden: false,
              },
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          height: '57px',
          padding: `0 ${customSpacing[12]}`,
          borderBottom: `1px solid ${colors.gray200}`,
          color: colors.dark,
        },
        head: {
          ...typography.bodyB14md,
        },
        body: {
          ...typography.body14md,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: `${customSpacing[4]} ${customSpacing[12]}`,
          borderRadius: borderRadius[4],
          background: alpha(colors.dark, 0.8),
          ...typography.body12sm,
          color: colors.white,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          gap: customSpacing[32],
        },
        indicator: {
          height: '3px',
          backgroundColor: colors.light,
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          minWidth: 'unset',
          padding: 'unset',
          ...typography.bodyB14md,
          color: colors.gray900,
          textTransform: 'none',

          ':hover': {
            color: colors.dark,
          },

          '&.Mui-selected': {
            color: colors.light,
          },

          '&.Mui-focusVisible': {
            backgroundColor: colors.purple100,
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      defaultProps: {
        size: 'medium',
        color: 'standard',
      },
      styleOverrides: {
        grouped: {
          ' :not(:first-of-type)': {
            margin: 'unset',
          },
        },
      },
    },
    MuiToggleButton: {
      defaultProps: {
        size: 'medium',
        color: 'standard',
      },
    },
    MuiAlert: {
      variants: [
        {
          props: { severity: 'error' },
          style: {
            backgroundColor: colors.red100,
            color: colors.red900,

            ' .MuiAlert-icon': {
              color: colors.red900,
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          padding: `${customSpacing[12]} ${customSpacing[32]} ${customSpacing[12]} ${customSpacing[16]}`,
        },
        icon: {
          fontSize: '20px',
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          marginBottom: 'unset',
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          position: 'unset',
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          minWidth: 'unset',
          maxWidth: '456px',
          padding: `${customSpacing[8]} ${customSpacing[8]} ${customSpacing[8]} ${customSpacing[20]}`,
          borderRadius: borderRadius[4],
          boxShadow: customShadows[6],

          [breakpoints.up('sm')]: {
            minWidth: 'unset',
          },

          '&.MuiPaper-root': {
            margin: 'unset',
          },
        },
        message: {
          padding: `0 ${customSpacing[12]} 0 0`,
          ...typography.bodyB16lg,
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        color: 'primary',
      },
      variants: [
        {
          props: { color: 'primary' },
          style: { color: colors.light },
        },
        {
          props: { color: 'secondary' },
          style: { color: colors.purple100 },
        },
        {
          props: { color: 'inherit' },
          style: { color: 'inherit' },
        },
      ],
    },
    MuiSkeleton: {
      defaultProps: {
        variant: 'rectangular',
        animation: 'wave',
      },
      styleOverrides: {
        root: {
          width: '100%',
          borderRadius: borderRadius[4],
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          padding: `${customSpacing[12]} ${customSpacing[16]}`,
        },
        list: {
          padding: 'unset',
        },
      },
    },
    MuiMenuItem: {
      defaultProps: { disableRipple: true },
      styleOverrides: {
        root: {
          padding: `${customSpacing[8]} ${customSpacing[16]}`,
          borderRadius: borderRadius[4],
          backgroundColor: colors.white,
          ...typography.body14md,
          color: colors.dark,

          '&:hover:not(.Mui-disabled, &:active)': {
            backgroundColor: colors.gray200,

            '&.Mui-selected': {
              backgroundColor: colors.purple700,
            },
          },

          '&:active': {
            backgroundColor: colors.purple100,
          },

          '&.Mui-disabled': {
            color: colors.gray600,
          },

          '&.Mui-selected': {
            backgroundColor: colors.light,
            color: colors.white,
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          border: `1px solid ${colors.gray300}`,
          boxShadow: customShadows[4],
          [`&:not(.${menuClasses.paper})`]: {
            padding: `${customSpacing[16]} ${customSpacing[20]}`,
          },
        },
      },
    },
    MuiSlider: {
      defaultProps: {
        color: 'primary',
        size: 'medium',
      },
      styleOverrides: {
        colorPrimary: {
          color: colors.dark,
        },
        root: {
          height: '4px',
          padding: `${customSpacing[8]} 0`,
        },
        rail: {
          height: '2px',
          opacity: 1,
        },
        mark: {
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          transform: 'translate(-50%, -50%)',
          opacity: 1,
        },
        thumb: {
          '&:hover, &.Mui-focusVisible': {
            boxShadow: `0px 0px 0px 8px ${alpha(colors.light, 0.1)}`,
          },
          '&.Mui-active': {
            boxShadow: `0px 0px 0px 14px ${alpha(colors.light, 0.1)}`,
          },
          '&::before': {
            boxShadow: 'unset',
          },
        },
      },
      variants: [
        {
          props: { color: 'primary' },
          style: {
            '& .MuiSlider-rail': {
              backgroundColor: colors.gray300,
            },
            '& .MuiSlider-mark': {
              backgroundColor: colors.gray300,
            },
            '& .MuiSlider-markActive': {
              backgroundColor: 'currentColor',
            },
          },
        },
      ],
    },
  },
});
