export enum RequestKey {
  AdminDashboards = 'AdminDashboards',
  AdminDashboard = 'AdminDashboard',
  AdminUpdateDashboard = 'AdminUpdateDashboard',
  AdminRenameDashboard = 'AdminRenameDashboard',
  AdminDeleteDashboard = 'AdminDeleteDashboard',
  AdminAssignDashboard = 'AdminAssignDashboard',
  AdminClientComments = 'AdminClientComments',
  AdminFolders = 'AdminFolders',
  AdminFoldersIllustrationsList = 'AdminFoldersIllustrationsList',
  AdminCreateFolder = 'AdminCreateFolder',
  AdminRenameFolder = 'AdminRenameFolder',
  AdminDeleteFolder = 'AdminDeleteFolder',
  AdminSearchIndividuals = 'AdminSearchIndividuals',
  AdminClients = 'AdminClients',
  AdminDeleteClient = 'AdminDeleteClient',
  AdminClientsBatch = 'AdminClientsBatch',
  AdminClientProfile = 'AdminClientProfile',
  AdminImpersonate = 'AdminImpersonate',
  AdminDeimpersonate = 'AdminDeimpersonate',
  Admins = 'Admins',
  AdminsAndAgents = 'AdminsAndAgents',
  AdminAgentProfile = 'AdminAgentProfile',
  AdminAgentProfiles = 'AdminAgentProfiles',
  AdminAllAgents = 'AdminAllAgents',
  AdminClientSurvey = 'AdminClientSurvey',
  AdminClientSurveyState = 'AdminClientSurveyState',
  AdminSurveyRecommendedIllustrations = 'AdminSurveyRecommendedIllustrations',
  AdminTraditionalIllustrations = 'AdminTraditionalIllustrations',
  AdminClientSurveys = 'AdminClientSurveys',
  AdminCreateClientSurvey = 'AdminCreateClientSurvey',
  AdminActiveSurveyIdByClientId = 'AdminActiveSurveyIdByClientId',
  AdminIndividualsByClientId = 'AdminIndividualsByClientId',
  AdminClientRecommendationRequests = 'AdminClientRecommendationRequests',
  AdminCreateRecommendationRequest = 'AdminCreateRecommendationRequest',
  AdminRegenerateRecommendationRequest = 'AdminRegenerateRecommendationRequest',
  AdminAssignAgent = 'AdminAssignAgent',
  AdminCreateAgentProfile = 'AdminCreateAgentProfile',
  AdminUpdateAgentProfile = 'AdminUpdateAgentProfile',
  AdminIllustrationsList = 'AdminIllustrationsList',
  AdminGenerateIllustrationRequestPlan = 'AdminGenerateIllustrationRequestPlan',
  AdminGetListOfIllustrationRequestSupportedProducts = 'AdminGetListOfIllustrationRequestSupportedProducts',
  AdminIllustration = 'AdminIllustration',
  AdminGetIllustrations = 'AdminGetIllustrations',
  AdminDeleteIllustration = 'AdminDeleteIllustration',
  AdminMoveIllustrations = 'AdminMoveIllustrations',
  AdminGetIllustrationsTemplate = 'AdminGetIllustrationsTemplate',
  AdminUploadIllustrations = 'AdminUploadIllustrations',
  AdminCreateIllustrationUpload = 'AdminCreateIllustrationUpload',
  AdminUpdateIllustrationUpload = 'AdminUpdateIllustrationUpload',
  AdminGetIllustrationFile = 'AdminGetIllustrationFile',
  AdminAddIllustrationFile = 'AdminAddIllustrationFile',
  AdminRemoveIllustrationFile = 'AdminRemoveIllustrationFile',
  AdminGenerateModeledIllustrationRequestPlan = 'AdminGenerateModeledIllustrationRequestPlan',
  AdminCreateModeledIllustration = 'AdminCreateModeledIllustration',
  AdminCalculateModeledIllustrations = 'AdminCalculateModeledIllustrations',
  AdminModeledIllustrationDebugFile = 'AdminModeledIllustrationDebugFile',
  AdminGetListOfModeledProducts = 'AdminGetListOfModeledProducts',
  AdminCarriers = 'AdminCarriers',
  AdminCarrier = 'AdminCarrier',
  AdminUpdateCarrier = 'AdminUpdateCarrier',
  AdminUpdateProduct = 'AdminUpdateProduct',
  AdminCreateProduct = 'AdminCreateProduct',
  AdminRetryIllustration = 'AdminRetryIllustration',
  AdminProductList = 'AdminProductList',
  AdminRiskClassList = 'AdminRiskClassList',
  AdminRiskClassMappings = 'AdminRiskClassMappings',
  AdminCreateClass = 'AdminCreateClass',
  AdminClientInformationReports = 'AdminClientInformationReports',
  AdminClientInformationReport = 'AdminClientInformationReport',
  AdminCreateClientsInformationReport = 'AdminCreateClientsInformationReport',
  AdminCreateIllustrationRequest = 'AdminCreateIllustrationRequest',
  AdminClientsIllustrationsList = 'AdminClientsIllustrationsList',
  AdminIllustrationArtifact = 'AdminIllustrationArtifact',
  AdminGenerateIllustrationFilePresignedUrlsForUpload = 'AdminGenerateIllustrationFilePresignedUrlsForUpload',
  AdminCreateIndividual = 'AdminCreateIndividual',
  AdminUpdateIndividual = 'AdminUpdateIndividual',
  AdminDocuments = 'AdminDocuments',
  AdminDocument = 'AdminDocument',
  AdminDeleteDocument = 'AdminDeleteDocument',
  AdminRenameDocument = 'AdminRenameDocument',
  AdminAddDocumentsToClientProfile = 'AdminAddDocumentsToClientProfile',
  AdminGetAdminUserGuideList = 'AdminGetAdminUserGuideList',
  AdminGetAgentUserGuideList = 'AdminGetAgentUserGuideList',
  AdminCreateUserGuide = 'AdminCreateUserGuide',
  AdminGetUserGuide = 'AdminGetUserGuide',
  AdminUpdateUserGuide = 'AdminUpdateUserGuide',
  AdminDeleteUserGuide = 'AdminDeleteUserGuide',
  AdminPresentation = 'AdminPresentation',
  AdminMarketingDocument = 'AdminMarketingDocument',
  AdminMarketingDocuments = 'AdminMarketingDocuments',
  AdminCreateMarketingDocument = 'AdminCreateMarketingDocument',
  AdminUpdateMarketingDocument = 'AdminUpdateMarketingDocument',
  AdminDeleteMarketingDocument = 'AdminDeleteMarketingDocument',

  AgentClients = 'AgentClients',
  AgentDeleteClient = 'AgentDeleteClient',
  AgentIndividualsByClientId = 'AgentIndividualsByClientId',
  AgentClientProfile = 'AgentClientProfile',
  AgentClientSurvey = 'AgentClientSurvey',
  AgentClientSurveyState = 'AgentClientSurveyState',
  AgentSurveyRecommendedIllustrations = 'AgentSurveyRecommendedIllustrations',
  AgentTraditionalIllustrations = 'AgentTraditionalIllustrations',
  AgentClientSurveys = 'AgentClientSurveys',
  AgentActiveSurveyIdByClientId = 'AgentActiveSurveyIdByClientId',
  AgentCreateClientSurvey = 'AgentCreateClientSurvey',
  AgentUpdateIndividual = 'AgentUpdateIndividual',
  AgentClientRecommendationRequests = 'AgentClientRecommendationRequests',
  AgentCreateRecommendationRequest = 'AgentCreateRecommendationRequest',
  AgentRegenerateRecommendationRequest = 'AgentRegenerateRecommendationRequest',
  AgentCreateIndividual = 'AgentCreateIndividual',
  AgentSearchClientProfiles = 'AgentSearchClientProfiles',
  AgentGetIllustrations = 'AgentGetIllustrations',
  AgentDeleteIllustration = 'AgentDeleteIllustration',
  AgentGetIllustrationFile = 'AgentGetIllustrationFile',
  AgentAddIllustrationFile = 'AgentAddIllustrationFile',
  AgentRemoveIllustrationFile = 'AgentRemoveIllustrationFile',
  AgentCreateModeledIllustration = 'AgentCreateModeledIllustration',
  AgentCalculateModeledIllustrations = 'AgentCalculateModeledIllustrations',
  AgentGenerateModeledIllustrationRequestPlan = 'AgentGenerateModeledIllustrationRequestPlan',
  AgentGetListOfModeledProducts = 'AgentGetListOfModeledProducts',
  AgentIllustrationArtifact = 'AgentIllustrationArtifact',
  AgentGenerateIllustrationFilePresignedUrlsForUpload = 'AgentGenerateIllustrationFilePresignedUrlsForUpload',
  AgentCreateIllustrationRequest = 'AgentCreateIllustrationRequest',
  AgentGenerateIllustrationRequestPlan = 'AgentGenerateIllustrationRequestPlan',
  AgentGetListOfIllustrationRequestSupportedProducts = 'AgentGetListOfIllustrationRequestSupportedProducts',
  AgentClientsBatch = 'AgentClientsBatch',
  AgentAdmins = 'AgentAdmins',
  AgentCarriers = 'AgentCarriers',
  AgentClientsIllustrationsList = 'AgentClientsIllustrationsList',
  AgentIllustration = 'AgentIllustration',
  AgentRetryIllustration = 'AgentRetryIllustration',
  AgentDashboards = 'AgentDashboards',
  AgentDashboard = 'AgentDashboard',
  AgentUpdateDashboard = 'AgentUpdateDashboard',
  AgentRenameDashboard = 'AgentRenameDashboard',
  AgentDeleteDashboard = 'AgentDeleteDashboard',
  AgentSearchIndividuals = 'AgentSearchIndividuals',
  AgentDocuments = 'AgentDocuments',
  AgentDocument = 'AgentDocument',
  AgentDeleteDocument = 'AgentDeleteDocument',
  AgentRenameDocument = 'AgentRenameDocument',
  AgentAddDocumentsToClientProfile = 'AgentAddDocumentsToClientProfile',
  AgentClientInformationReports = 'AgentClientInformationReports',
  AgentClientInformationReport = 'AgentClientInformationReport',
  AgentCreateClientsInformationReport = 'AgentCreateClientsInformationReport',
  AgentClientComments = 'AgentClientComments',
  AgentGetAgentUserGuideList = 'AgentGetAgentUserGuideList',
  AgentUserGuide = 'AgentUserGuide',
  AgentPresentation = 'AgentPresentation',
  AgentMarketingDocument = 'AgentMarketingDocument',
  AgentMarketingDocuments = 'AgentMarketingDocuments',
  AgentAcceptTermOfService = 'AgentAcceptTermOfService',
  AgentUsersWithAccessTo = 'AgentUsersWithAccessTo',

  ClientHousehold = 'ClientHousehold',
  ClientInvitationInfo = 'ClientInvitationInfo',

  SurveyRecommendedIllustrations = 'SurveyRecommendedIllustrations',
  TraditionalIllustrations = 'TraditionalIllustrations',
  UserInfo = 'UserInfo',

  DownloadFile = 'DownloadFile',
}
