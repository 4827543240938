import axios from 'axios';
import { init } from './fetchers/PlatformBrowserService';

export const apiVersionHeaderName = 'x-api-version';

export const initApi = () => {
  const defaultConfig = axios.defaults;
  defaultConfig.withCredentials = true;
  defaultConfig.xsrfCookieName = 'csrftoken';
  defaultConfig.xsrfHeaderName = 'x-csrftoken';

  axios.interceptors.request.use((config) => {
    config.headers[apiVersionHeaderName] = import.meta.env.VITE_API_VERSION;
    if (
      [
        '/api/admin/illustration-hub/modeled-illustrations/debug-file',
        '/api/admin/illustration-hub/illustrations/download-xlsx-template',
      ].includes(config.url as string)
    ) {
      config.responseType = 'blob';
    }
    return config;
  });
  init();
};
