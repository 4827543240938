import { tokens } from 'src/components/assets/tokens/tokens';

export const CONFIGURATIONS_CHART_MIN_HEIGHT = 400;
export const CONFIGURATIONS_CHART_INITIAL_HEIGHT = 520;
export const CONFIGURATIONS_CHART_MAX_HEIGHT = 1110;
export const CONFIGURATIONS_CHART_X_AXIS_HEIGHT = 30;
export const CONFIGURATIONS_CHART_MARGIN_TOP = 8;
export const CONFIGURATIONS_CHART_MARGIN_BOTTOM = 28;
export const CONFIGURATIONS_CHART_MARGIN_LEFT = 16;
export const CONFIGURATIONS_CHART_MARGIN_RIGHT = 12;
export const CONFIGURATIONS_CHART_AXIS_TICK_MARGIN = 12;
export const CONFIGURATIONS_CHART_TOOLTIP_SIZE = 32;
export const CONFIGURATIONS_CHART_ACTIVE_DOT_SIZE = 7;
export const CONFIGURATIONS_CHART_DOT_SIZE = 8;
export const CONFIGURATIONS_CHART_END_DOT_SIZE = 10;
export const CONFIGURATIONS_CHART_AXIS_COLOR = tokens.colors.secondary.gray[700];

export const SIDE_PANEL_MIN_WIDTH = 350;
export const SIDE_PANEL_INITIAL_WIDTH = 350;
export const SIDE_PANEL_MAX_WIDTH = 550;

export const CONFIGURATIONS_CHART_MARGIN = {
  bottom: CONFIGURATIONS_CHART_MARGIN_BOTTOM,
  top: CONFIGURATIONS_CHART_MARGIN_TOP,
  left: CONFIGURATIONS_CHART_MARGIN_LEFT,
  right: CONFIGURATIONS_CHART_MARGIN_RIGHT,
};

export const defaultVerticalSize = {
  width: '100%',
  height: CONFIGURATIONS_CHART_INITIAL_HEIGHT,
};

export const defaultHorizontalSize = {
  width: SIDE_PANEL_INITIAL_WIDTH,
  height: '100%',
};

export const enableRight = {
  top: false,
  right: true,
  bottom: false,
  left: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
};

export const enableBottom = {
  top: false,
  right: false,
  bottom: true,
  left: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
};

export const resizingGrid = [5, 5] as [number, number];

export const resizeHandleStyles = {
  bottom: {
    display: 'flex',
    alignItems: 'center',
    height: '1px',
    bottom: 'unset',
  },
  right: {
    display: 'flex',
    justifyContent: 'center',
    width: '1px',
    right: 0,
  },
};
