import { StrictMode, useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { AppWithProfiler } from './App';

if (import.meta.env.MODE !== 'development') {
  Sentry.init({
    dsn: 'https://be133f63b40e4d4ca24f7afb8a7cb18d@o4505466980270080.ingest.sentry.io/4505466982498304',
    autoSessionTracking: true,
    environment: import.meta.env.MODE,
    normalizeDepth: 10,
    sendClientReports: true,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 1.0,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // This enables automatic instrumentation (highly recommended),
    // but is not necessary for purely manual usage
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.browserProfilingIntegration(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^\//, // relative urls
      /^https?:\/\/qa\.optifino\.build\/api/,
    ],
    profilesSampleRate: 1.0,
    // https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-platformidentifier-nameignore-errors-
    ignoreErrors: [
      /^Network Error$/,
      /^Fetch Error$/,
      /^XHR Error$/,
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
    ],
  });

  import('@sentry/react').then((lazyLoadedSentry) => {
    Sentry.addIntegration(
      lazyLoadedSentry.replayIntegration({
        networkDetailAllowUrls: [window.location.origin],
        networkCaptureBodies: true,
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: true,
        unblock: ['svg'],
      }),
    );
  });
}

createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <AppWithProfiler />
  </StrictMode>,
);
