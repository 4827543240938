import { memo } from 'react';
import { ErrorPage } from 'src/components/ErrorPage/ErrorPage';

export const ErrorBoundaryPage = memo(() => (
  <ErrorPage
    title='Something went wrong'
    description={`We're having some issues with the page display, we're doing our best to fix it`}
  />
));

ErrorBoundaryPage.displayName = 'ErrorBoundaryPage';
