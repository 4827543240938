import { memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material';
import LogoSvg from '../../assets/icons/logo.svg';
import LogoLightSvg from '../../assets/icons/logo-light.svg';
import LogoGraySvg from '../../assets/icons/logo-gray.svg';

interface LogoProps {
  width: string;
  height: string;
  type?: 'dark' | 'light' | 'gray';
  navigateTo?: string;
}

export const Logo = memo<LogoProps>(({ width, height, type = 'dark', navigateTo }) => {
  const navigate = useNavigate();

  const handleLogoClick = useCallback(() => {
    if (navigateTo) {
      navigate(navigateTo);
    }
  }, [navigate, navigateTo]);

  const Icon = useMemo(() => {
    return mapTypeToSvg[type];
  }, [type]);

  return (
    <StyledWrapper width={width} height={height} navigateTo={navigateTo} onClick={handleLogoClick}>
      <Icon width={width} height={height} />
    </StyledWrapper>
  );
});

Logo.displayName = 'Logo';

interface StyledWrapperProps {
  height: string;
  width: string;
  navigateTo?: string;
}

const StyledWrapper = styled('nav', {
  name: 'Logo',
})<StyledWrapperProps>(({ height, width, navigateTo }) => ({
  display: 'inline-flex',
  flexGrow: 0,
  flexShrink: 0,
  lineHeight: 0,
  height,
  width,
  cursor: navigateTo ? 'pointer' : 'auto',
}));

const mapTypeToSvg = {
  dark: LogoSvg,
  light: LogoLightSvg,
  gray: LogoGraySvg,
};
