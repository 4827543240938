import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps, styled } from '@mui/material';
import { memo } from 'react';
import { tokens } from '../assets/tokens/tokens';

export type TooltipProps = Omit<MuiTooltipProps, 'disableInteractive'> & {
  interactive?: boolean;
  disabled?: boolean;
  withoutWrapper?: boolean;
};

export const Tooltip = memo<TooltipProps>(({ title, interactive = false, disabled, withoutWrapper, ...props }) => {
  return (
    <StyledTooltip
      title={disabled ? '' : title}
      placement='top'
      disableInteractive={!interactive}
      enterDelay={300}
      enterTouchDelay={700}
      leaveDelay={0}
      leaveTouchDelay={1500}
      {...props}
    >
      {withoutWrapper ? props.children : <span>{props.children}</span>}
    </StyledTooltip>
  );
});

Tooltip.displayName = 'Tooltip';

const StyledTooltip = styled(
  ({ className, ...props }: MuiTooltipProps) => <MuiTooltip {...props} classes={{ popper: className }} />,
  {
    name: 'Tooltip',
    shouldForwardProp: (prop) => prop !== 'touchRippleRef',
  },
)({
  '& .MuiTooltip-tooltip': {
    ...tokens.typography.bodySm,
    padding: `${tokens.spacing[1]} ${tokens.spacing[2]}`,
    borderRadius: tokens.borderRadius[1],
    background: tokens.colors.primary.dark80,
    color: tokens.colors.primary.white,
    maxWidth: '420px',
    boxShadow: tokens.shadows[3],
  },

  '&.MuiTooltip-popper[data-popper-placement*="bottom"]': {
    '& .MuiTooltip-tooltip': {
      marginTop: tokens.spacing[0.5],
    },
  },

  '&.MuiTooltip-popper[data-popper-placement*="top"]': {
    '& .MuiTooltip-tooltip': {
      marginBottom: tokens.spacing[0.5],
    },
  },

  '&.MuiTooltip-popper[data-popper-placement*="left"]': {
    '& .MuiTooltip-tooltip': {
      marginRight: tokens.spacing[0.5],
    },
  },

  '&.MuiTooltip-popper[data-popper-placement*="right"]': {
    '& .MuiTooltip-tooltip': {
      marginLeft: tokens.spacing[0.5],
    },
  },
});
