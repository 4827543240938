import { alpha } from '@mui/material';
import { colors } from './colors';

export const shadows = {
  1: `0px 8px 24px 0px ${alpha(colors.primary.light, 0.15)}`,
  2: `2px 0px 4px 0px ${alpha(colors.secondary.gray[800], 0.2)}`,
  3: `-8px 0px 32px 0px ${alpha(colors.secondary.gray[400], 0.15)}`,
  4: `0px 12px 20px -4px ${alpha('#1a1a1a', 0.14)}, 0px 4px 6px -2px ${alpha('#1a1a1a', 0.25)}`,
  5: `0px 4px 6px -2px ${alpha('#1a1a1a', 0.16)}`,
  6: `0px 8px 20px 0px ${alpha(colors.primary.light, 0.07)}`,
};
