import axios from 'axios';
import { memo, ReactElement, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { Permission } from 'src/generatedApi';
import { ContextType } from 'src/types';
import { Paths } from 'src/paths';
import { UNRESTRICTED_ACCESS } from '../../constants/appFlags';
import { useBackUrl } from '../../hooks/useBackUrl';

type ProtectedRouteProps = {
  children: ReactElement;
} & (
  | {
      permission: Permission;
      onlyUserCheck?: never;
    }
  | {
      permission?: never;
      onlyUserCheck: boolean;
    }
);

export const ProtectedRoute = memo<ProtectedRouteProps>(({ children, permission, onlyUserCheck }) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { navigateAndKeepBackUrl } = useBackUrl();

  const context = useOutletContext<ContextType>();

  const showChildren = useMemo(() => {
    return context && !context.isFetching;
  }, [context]);

  useEffect(() => {
    const authInterceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error?.response?.status === 401) {
          navigateAndKeepBackUrl(`/${Paths.SIGNIN}`, `${pathname}${search}`);
        } else {
          throw error;
        }
      },
    );
    return () => {
      axios.interceptors.response.eject(authInterceptor);
    };
  }, [navigateAndKeepBackUrl, pathname, search]);

  useEffect(() => {
    if (UNRESTRICTED_ACCESS) return;

    if (context.userInfo && !context.isFetching) {
      const { userInfo } = context;
      if (!userInfo.isVerified) {
        navigateAndKeepBackUrl(`/${Paths.OTP}`, `${pathname}${search}`);
        return;
      }

      const hasPermission = onlyUserCheck || (permission && userInfo.checkPermission(permission));
      if (!hasPermission) {
        navigate(`/${Paths.ERROR_ACCESS_DENIED}`);
        return;
      }
    }
  }, [navigateAndKeepBackUrl, context, permission, navigate, pathname, search, onlyUserCheck]);

  return UNRESTRICTED_ACCESS || showChildren ? children : null;
});

ProtectedRoute.displayName = 'ProtectedRoute';
