import { AutocompleteOption } from 'src/components/Autocomplete/util/types';
import { Paths } from 'src/paths';
import {
  ApiError,
  IndividualResponse,
  IndividualTitleEnum,
  type Permission,
  UserListItemResponse,
  UserResponse,
  ValidationError,
} from './generatedApi';
import { DurationTypeEnum } from './generatedApi/constants/durationTypeEnum';

export type ContextType = {
  userInfo?: UserInfo;
  isFetching: boolean;
  isError: boolean;
  refetch: () => void;
};

export enum YesNoOption {
  Yes = 'yes',
  No = 'no',
  Empty = '',
}

export type DeepRequired<T> = {
  [K in keyof T]-?: DeepRequired<Exclude<T[K], null>>;
};

export type DeepPartial<T> = T extends Array<unknown>
  ? T
  : T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export interface GeneratedApiError extends ApiError {
  body: {
    detail?: Array<ValidationError>;
  };
}

/** Get ordering from generated API request */
export type GetOrdering<T extends { ordering?: Array<string> }> = NonNullable<T['ordering']>[number];

export const DurationType = {
  [DurationTypeEnum.YEAR]: 'Year' as const,
  [DurationTypeEnum.UNTIL]: 'Until' as const,
  [DurationTypeEnum.FULL_PAY]: 'FullPay' as const,
};

export type ProductTypeOption = AutocompleteOption & { description: string; groupLabel?: string };

export type IndividualOption = AutocompleteOption &
  IndividualResponse & {
    hidden?: boolean;
    needsReview?: boolean;
  };

export type ClientOption = AutocompleteOption & {
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  hidden?: boolean;
};

export type CarrierOption = AutocompleteOption & { abbreviation: string; logoUrl: string | null };

export type UserOption = AutocompleteOption & {
  firstName: string;
  lastName: string;
  colorIndex: number;
};

export enum Portal {
  Admin = 'admin',
  Agent = 'agent',
  Client = 'client',
}

export type UserInfo = UserResponse & {
  permissions: Array<Permission>;
  impersonateId: string | null;
  portal: Portal;
  portalPath: Paths;
  isAdminPortal: boolean;
  isAgentPortal: boolean;
  checkPermission: (permission: Permission) => boolean;
};

export interface ClientPageTabProps {
  open: boolean;
  clientId: string;
  usersWithAccess: Array<UserListItemResponse>;
}

export type UserLike = {
  title?: IndividualTitleEnum | null;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  fullName?: string;
};

export enum FileExtension {
  pdf = 'pdf',
  png = 'png',
  jpeg = 'jpeg',
  svg = 'svg',
  xls = 'xls',
  xlsx = 'xlsx',
  doc = 'doc',
  docx = 'docx',
  webm = 'webm',
}

export enum FileMimeType {
  pdf = 'application/pdf',
  png = 'image/png',
  jpeg = 'image/jpeg',
  svg = 'image/svg+xml',
  xls = 'application/vnd.ms-excel',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  webm = 'video/webm',
}
