import {
  CONFIGURATIONS_CHART_EXPORT_HEIGHT,
  CONFIGURATIONS_CHART_EXPORT_WIDTH,
} from 'src/pages/AdminPortal/CalculatorPage/components/CalculatorDialogManager/Dialogs/ConfigurationsChartExportDialog/util/constants';

export enum COMPARISON_EXPORT_PAGE {
  PRODUCTS_COMPARISON = 'PRODUCTS_COMPARISON',
  PRODUCTS_VS_TRADITIONAL = 'PRODUCTS_VS_TRADITIONAL',
}

export const ASPECT_RATIO = CONFIGURATIONS_CHART_EXPORT_WIDTH / CONFIGURATIONS_CHART_EXPORT_HEIGHT;
