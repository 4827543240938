import { Suspense, FC, memo, ReactNode } from 'react';

export const Loadable = <T extends JSX.IntrinsicAttributes>(Component: FC<T>, fallback: ReactNode = null) => {
  const LoadableInner = memo((props: T) => (
    <Suspense fallback={fallback}>
      <Component {...props} />
    </Suspense>
  ));

  LoadableInner.displayName = 'LoadableInner';

  return LoadableInner;
};

Loadable.displayName = 'Loadable';
