import { tokens } from '../../assets/tokens/tokens';
import { DialogSeverity } from './types';

export const mapSeverityToColor: Record<DialogSeverity, string> = {
  default: tokens.colors.primary.light,
  info: tokens.colors.tertiary.blue[500],
  error: tokens.colors.tertiary.red[400],
  warning: tokens.colors.tertiary.amber[400],
  success: tokens.colors.tertiary.green[500],
};
