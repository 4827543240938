import { tokens } from 'src/components/assets/tokens/tokens';
import { CONFIGURATIONS_CHART_AXIS_COLOR } from 'src/widgets/ConfigurationsChart/util/constants';

export const CONFIGURATIONS_CHART_EXPORT_ID = 'configurationsChartExport';
export const CONFIGURATIONS_CHART_EXPORT_PREVIEW_ID = `${CONFIGURATIONS_CHART_EXPORT_ID}-preview`;
export const CONFIGURATIONS_CHART_EXPORT_WIDTH = 1900;
export const CONFIGURATIONS_CHART_EXPORT_HEIGHT = 770;
export const CONFIGURATIONS_CHART_EXPORT_PADDING_VERTICAL = 32;
export const CONFIGURATIONS_CHART_EXPORT_PADDING_LEFT = 24;
export const CONFIGURATIONS_CHART_EXPORT_PADDING_RIGHT = 48;
export const CONFIGURATIONS_CHART_EXPORT_TITLE_ID = `${CONFIGURATIONS_CHART_EXPORT_ID}-title`;
export const CONFIGURATIONS_CHART_EXPORT_TITLE_OFFSET_TOP = -8;
export const CONFIGURATIONS_CHART_EXPORT_TITLE_FONT_SIZE = 36;
export const CONFIGURATIONS_CHART_EXPORT_AXIS_FONT_SIZE = 28;
export const CONFIGURATIONS_CHART_EXPORT_X_AXIS_LABEL_ID = `${CONFIGURATIONS_CHART_EXPORT_ID}-x-axis-label`;
export const CONFIGURATIONS_CHART_EXPORT_X_AXIS_LABEL_OFFSET = 12;
export const CONFIGURATIONS_CHART_EXPORT_LEGEND_ID = `${CONFIGURATIONS_CHART_EXPORT_ID}-legend`;
export const CONFIGURATIONS_CHART_EXPORT_LEGEND_OFFSET_TOP = 80;
export const CONFIGURATIONS_CHART_EXPORT_LEGEND_WIDTH = CONFIGURATIONS_CHART_EXPORT_WIDTH - 64;
export const CONFIGURATIONS_CHART_EXPORT_LEGEND_COLUMN_GAP = 48;
export const CONFIGURATIONS_CHART_EXPORT_LEGEND_ROW_GAP = 32;
export const CONFIGURATIONS_CHART_EXPORT_LEGEND_ITEM_GAP = 8;
export const CONFIGURATIONS_CHART_EXPORT_LEGEND_ITEM_MARKER_WIDTH = 28;
export const CONFIGURATIONS_CHART_EXPORT_LEGEND_ITEM_MARKER_HEIGHT = 20;
export const CONFIGURATIONS_CHART_EXPORT_LEGEND_ITEM_FONT_SIZE = 30;
export const CONFIGURATIONS_CHART_EXPORT_AXIS_COLOR = CONFIGURATIONS_CHART_AXIS_COLOR;
export const CONFIGURATIONS_CHART_EXPORT_TITLE_COLOR = tokens.colors.primary.dark;
export const CONFIGURATIONS_CHART_EXPORT_LEGEND_COLOR = tokens.colors.primary.dark;
export const CONFIGURATIONS_CHART_EXPORT_OUTLINE_COLOR = '#E4E2E8';
export const CONFIGURATIONS_CHART_EXPORT_OUTLINE_WIDTH = 4;

export const ELLIPSIS = '\u2026';
