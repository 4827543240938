import {
  browserVersion,
  fullBrowserVersion,
  isAndroid,
  isChrome,
  isEdge,
  isFirefox,
  isIE,
  isOpera,
  isSafari,
  isSamsungBrowser,
} from 'react-device-detect';

export const isBrowserVersionSupported = () => {
  if (isChrome && Number(browserVersion) < 119) return false;
  if (isEdge && Number(browserVersion) < 119) return false;
  if (isSafari && Number(fullBrowserVersion) < 17.4) return false;
  if (isFirefox && Number(browserVersion) < 121) return false;
  if (isOpera && Number(browserVersion) < 105) return false;
  if (isIE) return false;

  if (isSamsungBrowser && Number(browserVersion) < 25) return false;

  if (isAndroid) {
    if (isChrome && Number(browserVersion) < 119) return false;
    if (isFirefox && Number(browserVersion) < 121) return false;
    if (isOpera && Number(browserVersion) < 105) return false;
  }

  return true;
};
