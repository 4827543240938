import { useNavigate, useSearchParams } from 'react-router-dom';
import { generateLinkWithBackUrl } from 'src/util/generateLinkWithBackUrl/generateLinkWithBackUrl';
import { BACK_URL_SEARCH_PARAM } from '../constants/shared/params';

export const useBackUrl = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchParamsBackUrl = searchParams.get(BACK_URL_SEARCH_PARAM);

  const generateLinkAndKeepBackUrl = (pathTo: string, newBackUrl?: string): string => {
    const backUrl = newBackUrl || searchParamsBackUrl;
    return generateLinkWithBackUrl(pathTo, backUrl);
  };

  const navigateAndKeepBackUrl = (pathTo: string, newBackUrl?: string): void => {
    navigate(generateLinkAndKeepBackUrl(pathTo, newBackUrl));
  };

  const navigateBack = (fallback: string = '/') => {
    navigate(searchParamsBackUrl || fallback);
  };

  return { backUrl: searchParamsBackUrl, generateLinkAndKeepBackUrl, navigateAndKeepBackUrl, navigateBack };
};
