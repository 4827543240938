export const typography = {
  h1: {
    fontFamily: 'Inter, Arial, sans-serif',
    fontWeight: '500',
    fontSize: '32px',
    lineHeight: '40px',
    letterSpacing: '0px',
  },
  h2: {
    fontFamily: 'Inter, Arial, sans-serif',
    fontWeight: '500',
    fontSize: '28px',
    lineHeight: '36px',
    letterSpacing: '0px',
  },
  h3: {
    fontFamily: 'Inter, Arial, sans-serif',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '0px',
  },
  h4: {
    fontFamily: 'Inter, Arial, sans-serif',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '0px',
  },
  h5: {
    fontFamily: 'Inter, Arial, sans-serif',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0px',
  },
  h6: {
    fontFamily: 'Inter, Arial, sans-serif',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0px',
  },
  h7: {
    fontFamily: 'Inter, Arial, sans-serif',
    fontWeight: '700',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0px',
  },
  h8: {
    fontFamily: 'Inter, Arial, sans-serif',
    fontWeight: '700',
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '0px',
  },
  bodyXl: {
    fontFamily: 'Inter, Arial, sans-serif',
    fontWeight: '400',
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '0px',
  },
  bodyLg: {
    fontFamily: 'Inter, Arial, sans-serif',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0px',
  },
  bodyMd: {
    fontFamily: 'Inter, Arial, sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0px',
  },
  bodySm: {
    fontFamily: 'Inter, Arial, sans-serif',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0px',
  },
  bodyXs: {
    fontFamily: 'Inter, Arial, sans-serif',
    fontWeight: '400',
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '0px',
  },
  bodyBoldXl: {
    fontFamily: 'Inter, Arial, sans-serif',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '0px',
  },
  bodyBoldLg: {
    fontFamily: 'Inter, Arial, sans-serif',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0px',
  },
  bodyBoldMd: {
    fontFamily: 'Inter, Arial, sans-serif',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0px',
  },
  bodyBoldSm: {
    fontFamily: 'Inter, Arial, sans-serif',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0px',
  },
  bodyBoldXs: {
    fontFamily: 'Inter, Arial, sans-serif',
    fontWeight: '500',
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '0px',
  },
  codeMd: {
    fontFamily: 'IBM Plex Mono, Inter, Arial, sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0px',
  },
  presentationSemiBold72: {
    fontFamily: 'Manrope, Arial, sans-serif',
    fontWeight: '600',
    fontSize: '72px',
    lineHeight: '100px',
    letterSpacing: '0px',
  },
  presentationMedium44: {
    fontFamily: 'Manrope, Arial, sans-serif',
    fontWeight: '500',
    fontSize: '44px',
    lineHeight: '60px',
    letterSpacing: '0px',
  },
  presentationRegular44: {
    fontFamily: 'Manrope, Arial, sans-serif',
    fontWeight: '400',
    fontSize: '44px',
    lineHeight: '60px',
    letterSpacing: '0px',
  },
  presentationThin44: {
    fontFamily: 'Manrope, Arial, sans-serif',
    fontWeight: '200',
    fontSize: '44px',
    lineHeight: '60px',
    letterSpacing: '0px',
  },
  presentationMedium28: {
    fontFamily: 'Inter, Arial, sans-serif',
    fontWeight: '500',
    fontSize: '28px',
    lineHeight: '36px',
    letterSpacing: '0px',
  },
  presentationRegular28: {
    fontFamily: 'Inter, Arial, sans-serif',
    fontWeight: '400',
    fontSize: '28px',
    lineHeight: '36px',
    letterSpacing: '0px',
  },
  presentationMedium24: {
    fontFamily: 'Manrope, Arial, sans-serif',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '36px',
    letterSpacing: '0px',
  },
  presentationRegular24: {
    fontFamily: 'Manrope, Arial, sans-serif',
    fontWeight: '400',
    fontSize: '24px',
    lineHeight: '36px',
    letterSpacing: '0px',
  },
  presentationSemiBold16: {
    fontFamily: 'Manrope, Arial, sans-serif',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0px',
  },
  presentationMedium16: {
    fontFamily: 'Manrope, Arial, sans-serif',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0px',
  },
  presentationRegular16: {
    fontFamily: 'Manrope, Arial, sans-serif',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0px',
  },
  presentationSemiBold14: {
    fontFamily: 'Manrope, Arial, sans-serif',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0px',
  },
  presentationMedium14: {
    fontFamily: 'Manrope, Arial, sans-serif',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0px',
  },
  presentationRegular14: {
    fontFamily: 'Manrope, Arial, sans-serif',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0px',
  },
  presentationItalic14: {
    fontFamily: 'Inter, Arial, sans-serif',
    fontStyle: 'italic',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0px',
  },
  presentationMedium12: {
    fontFamily: 'Manrope, Arial, sans-serif',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0px',
  },
  presentationRegular12: {
    fontFamily: 'Manrope, Arial, sans-serif',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0px',
  },
  presentationItalic12: {
    fontFamily: 'Inter, Arial, sans-serif',
    fontStyle: 'italic',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0px',
  },
};
