import { memo, useMemo } from 'react';
import { styled } from '@mui/material';
import { iconNameToSVGComponent } from './iconNameToSVGComponent';

export type IconName = keyof typeof iconNameToSVGComponent;

export interface SvgIconProps extends React.SVGProps<SVGSVGElement> {
  iconName: IconName;
  width?: string;
  height?: string;
}

export const SvgIcon = memo<SvgIconProps>(({ iconName, width = '20px', height = '20px', className, ...rest }) => {
  const Icon = useMemo(() => iconNameToSVGComponent[iconName], [iconName]);

  return (
    <StyledWrapper width={width} height={height} className={className}>
      {Icon && <Icon width={width} height={height} {...rest} />}
    </StyledWrapper>
  );
});

SvgIcon.displayName = 'SvgIcon';

interface StyledWrapperProps {
  width: string;
  height: string;
}

const StyledWrapper = styled('span', {
  name: 'SvgIcon',
})<StyledWrapperProps>(({ height, width }) => ({
  display: 'inline-flex',
  flexGrow: 0,
  flexShrink: 0,
  lineHeight: 0,
  height,
  width,
}));
