import { useEffect } from 'react';
import type { EffectCallback } from 'react';

/**
 * A hook that runs an effect only once (at mount).
 * @param {EffectCallback} effect - The effect to run.
 */
export function useEffectOnce(effect: EffectCallback) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
}
