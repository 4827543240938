import IconAddDashboard from '../assets/icons/add-dashboard.svg';
import IconAdd from '../assets/icons/add.svg';
import IconArrowDropDown from '../assets/icons/arrow-drop-down.svg';
import IconAutoFix from '../assets/icons/auto-fix.svg';
import IconCalculator from '../assets/icons/calculator.svg';
import IconCalendarAlert from '../assets/icons/calendar-alert.svg';
import IconCalendar from '../assets/icons/calendar.svg';
import IconCheckBaseline from '../assets/icons/check-baseline.svg';
import IconCheckText from '../assets/icons/check-text.svg';
import IconCheck from '../assets/icons/check.svg';
import IconCheckboxChecked from '../assets/icons/checkbox-checked.svg';
import IconCheckboxIndeterminate from '../assets/icons/checkbox-indeterminate.svg';
import IconCheckboxUnchecked from '../assets/icons/checkbox-unchecked.svg';
import IconChevronDown from '../assets/icons/chevron-down.svg';
import IconChevronLeft from '../assets/icons/chevron-left.svg';
import IconChevronRight from '../assets/icons/chevron-right.svg';
import IconChevronUp from '../assets/icons/chevron-up.svg';
import IconChipCross from '../assets/icons/chip-cross.svg';
import IconCombine from '../assets/icons/combine.svg';
import IconCopy from '../assets/icons/copy.svg';
import IconCrossBaseline from '../assets/icons/cross-baseline.svg';
import IconCross from '../assets/icons/cross.svg';
import IconCube from '../assets/icons/cube.svg';
import IconDashboard from '../assets/icons/dashboard.svg';
import IconDelete from '../assets/icons/delete.svg';
import IconDot from '../assets/icons/dot.svg';
import IconDownload from '../assets/icons/download.svg';
import IconDrawer1035 from '../assets/icons/drawer-1035.svg';
import IconDrawerDeathBenefit from '../assets/icons/drawer-death-benefit.svg';
import IconDrawerDob from '../assets/icons/drawer-dob.svg';
import IconDrawerDuration from '../assets/icons/drawer-duration.svg';
import IconDrawerNicotine from '../assets/icons/drawer-nicotine.svg';
import IconDrawerPremium from '../assets/icons/drawer-premium.svg';
import IconDrawerProductType from '../assets/icons/drawer-product-type.svg';
import IconDrawerRiskClass from '../assets/icons/drawer-risk-class.svg';
import IconDrawerRor from '../assets/icons/drawer-ror.svg';
import IconDrawerSex from '../assets/icons/drawer-sex.svg';
import IconDrawerState from '../assets/icons/drawer-state.svg';
import IconDuplicate from '../assets/icons/duplicate.svg';
import IconEdit from '../assets/icons/edit.svg';
import IconErrorOutline from '../assets/icons/error-outline.svg';
import IconExpandContent from '../assets/icons/expand-content.svg';
import IconFileDocumentOutline from '../assets/icons/file-document-outline.svg';
import IconFileDocumentPlus from '../assets/icons/file-document-plus.svg';
import IconFileMove from '../assets/icons/file-move.svg';
import IconFileEmptyOutline from '../assets/icons/file-empty-outline.svg';
import IconFilterList from '../assets/icons/filter-list.svg';
import IconFilterOff from '../assets/icons/filter-off.svg';
import IconFolder from '../assets/icons/folder.svg';
import IconFormatAlignCenter from '../assets/icons/format-align-center.svg';
import IconFormatAlignJustify from '../assets/icons/format-align-justify.svg';
import IconFormatAlignLeft from '../assets/icons/format-align-left.svg';
import IconFormatAlignRight from '../assets/icons/format-align-right.svg';
import IconFormatBold from '../assets/icons/format-bold.svg';
import IconFormatColorText from '../assets/icons/format-color-text.svg';
import IconFormatItalic from '../assets/icons/format-italic.svg';
import IconFormatListBulleted from '../assets/icons/format-list-bulleted.svg';
import IconFormatListNumbered from '../assets/icons/format-list-numbered.svg';
import IconFormatStrikethrough from '../assets/icons/format-strikethrough.svg';
import IconFormatUnderlined from '../assets/icons/format-underlined.svg';
import IconGroups from '../assets/icons/groups.svg';
import IconHome from '../assets/icons/home.svg';
import IconInfo from '../assets/icons/info.svg';
import IconInsertMedia from '../assets/icons/insert-media.svg';
import IconLink from '../assets/icons/link.svg';
import IconLogFile from '../assets/icons/log-file.svg';
import IconLogout from '../assets/icons/logout.svg';
import IconLogo from '../assets/icons/logo.svg';
import IconMail from '../assets/icons/mail.svg';
import IconMenuRounded from '../assets/icons/menu-rounded.svg';
import IconMinus from '../assets/icons/minus.svg';
import IconNotes from '../assets/icons/notes.svg';
import IconOpenInNew from '../assets/icons/open-in-new.svg';
import IconOpenedFolder from '../assets/icons/opened-folder.svg';
import IconOutlineCreateNewFolder from '../assets/icons/outline-create-new-folder.svg';
import IconOutlineFolder from '../assets/icons/outline-folder.svg';
import IconOutlinePercentage from '../assets/icons/outline-percentage.svg';
import IconPdfSolid from '../assets/icons/pdf-solid.svg';
import IconPerson from '../assets/icons/person.svg';
import IconPhone from '../assets/icons/phone.svg';
import IconQuestionMark from '../assets/icons/question-mark.svg';
import IconRecommendations from '../assets/icons/recommendations.svg';
import IconRefresh from '../assets/icons/refresh.svg';
import IconRelatives from '../assets/icons/relatives.svg';
import IconSave from '../assets/icons/save.svg';
import IconSaveAs from '../assets/icons/save-as.svg';
import IconSearch from '../assets/icons/search.svg';
import IconSettings from '../assets/icons/settings.svg';
import IconSexFemaleBold from '../assets/icons/sex-female-bold.svg';
import IconSexMaleBold from '../assets/icons/sex-male-bold.svg';
import IconSlack from '../assets/icons/slack.svg';
import IconSlash from '../assets/icons/slash.svg';
import IconSortAscending from '../assets/icons/sort-ascending.svg';
import IconSortDescending from '../assets/icons/sort-descending.svg';
import IconSorting from '../assets/icons/sorting.svg';
import IconSwitchChecked from '../assets/icons/switch-checked.svg';
import IconSwitchUnchecked from '../assets/icons/switch-unchecked.svg';
import IconSymbolUsd from '../assets/icons/symbol-usd.svg';
import IconThreeDotsHorizontal from '../assets/icons/three-dots-horizontal.svg';
import IconThreeDotsVertical from '../assets/icons/three-dots-vertical.svg';
import IconThreeDots from '../assets/icons/three-dots.svg';
import IconTraditionalInvestment from '../assets/icons/traditional-investment.svg';
import IconUpload from '../assets/icons/upload.svg';
import IconUserPlus from '../assets/icons/user-plus.svg';
import IconUserSearch from '../assets/icons/user-search.svg';
import IconUser from '../assets/icons/user.svg';
import IconUsers from '../assets/icons/users.svg';
import IconVideo from '../assets/icons/video.svg';
import IconViewColumns from '../assets/icons/view-columns.svg';
import IconWysiwygAddLink from '../assets/icons/wysiwyg-add-link.svg';
import IconWysiwygMention from '../assets/icons/wysiwyg-mention.svg';
import IconXlsSolid from '../assets/icons/xls-solid.svg';

export const iconNameToSVGComponent = {
  'add-dashboard': IconAddDashboard,
  add: IconAdd,
  'arrow-drop-down': IconArrowDropDown,
  'auto-fix': IconAutoFix,
  calculator: IconCalculator,
  calendar: IconCalendar,
  'calendar-alert': IconCalendarAlert,
  'cross-baseline': IconCrossBaseline,
  cross: IconCross,
  check: IconCheck,
  'check-baseline': IconCheckBaseline,
  'check-text': IconCheckText,
  'checkbox-checked': IconCheckboxChecked,
  'checkbox-unchecked': IconCheckboxUnchecked,
  'checkbox-indeterminate': IconCheckboxIndeterminate,
  'chevron-down': IconChevronDown,
  'chevron-left': IconChevronLeft,
  'chevron-right': IconChevronRight,
  'chevron-up': IconChevronUp,
  'chip-cross': IconChipCross,
  combine: IconCombine,
  copy: IconCopy,
  cube: IconCube,
  dashboard: IconDashboard,
  delete: IconDelete,
  dot: IconDot,
  download: IconDownload,
  'drawer-1035': IconDrawer1035,
  'drawer-death-benefit': IconDrawerDeathBenefit,
  'drawer-dob': IconDrawerDob,
  'drawer-duration': IconDrawerDuration,
  'drawer-nicotine': IconDrawerNicotine,
  'drawer-premium': IconDrawerPremium,
  'drawer-product-type': IconDrawerProductType,
  'drawer-risk-class': IconDrawerRiskClass,
  'drawer-ror': IconDrawerRor,
  'drawer-sex': IconDrawerSex,
  'drawer-state': IconDrawerState,
  duplicate: IconDuplicate,
  edit: IconEdit,
  'error-outline': IconErrorOutline,
  'expand-content': IconExpandContent,
  'file-document-outline': IconFileDocumentOutline,
  'file-document-plus': IconFileDocumentPlus,
  'file-empty-outline': IconFileEmptyOutline,
  'file-move': IconFileMove,
  'filter-list': IconFilterList,
  'filter-off': IconFilterOff,
  'format-align-center': IconFormatAlignCenter,
  'format-align-justify': IconFormatAlignJustify,
  'format-align-left': IconFormatAlignLeft,
  'format-align-right': IconFormatAlignRight,
  'format-bold': IconFormatBold,
  'format-italic': IconFormatItalic,
  'format-list-bulleted': IconFormatListBulleted,
  'format-list-numbered': IconFormatListNumbered,
  'format-strikethrough': IconFormatStrikethrough,
  'format-text-color': IconFormatColorText,
  'format-underlined': IconFormatUnderlined,
  groups: IconGroups,
  home: IconHome,
  info: IconInfo,
  'insert-media': IconInsertMedia,
  folder: IconFolder,
  link: IconLink,
  'log-file': IconLogFile,
  logout: IconLogout,
  logo: IconLogo,
  mail: IconMail,
  'menu-rounded': IconMenuRounded,
  minus: IconMinus,
  notes: IconNotes,
  'open-in-new': IconOpenInNew,
  'opened-folder': IconOpenedFolder,
  'outline-create-new-folder': IconOutlineCreateNewFolder,
  'outline-folder': IconOutlineFolder,
  'outline-percentage': IconOutlinePercentage,
  'pdf-solid': IconPdfSolid,
  person: IconPerson,
  phone: IconPhone,
  'question-mark': IconQuestionMark,
  recommendations: IconRecommendations,
  refresh: IconRefresh,
  relatives: IconRelatives,
  save: IconSave,
  'save-as': IconSaveAs,
  search: IconSearch,
  settings: IconSettings,
  'sex-female-bold': IconSexFemaleBold,
  'sex-male-bold': IconSexMaleBold,
  slack: IconSlack,
  slash: IconSlash,
  'sort-ascending': IconSortAscending,
  'sort-descending': IconSortDescending,
  sorting: IconSorting,
  'switch-checked': IconSwitchChecked,
  'switch-unchecked': IconSwitchUnchecked,
  'symbol-usd': IconSymbolUsd,
  'three-dots-horizontal': IconThreeDotsHorizontal,
  'three-dots-vertical': IconThreeDotsVertical,
  'three-dots': IconThreeDots,
  'traditional-investment': IconTraditionalInvestment,
  upload: IconUpload,
  'user-plus': IconUserPlus,
  'user-search': IconUserSearch,
  user: IconUser,
  users: IconUsers,
  video: IconVideo,
  'view-columns': IconViewColumns,
  'wysiwyg-add-link': IconWysiwygAddLink,
  'wysiwyg-mention': IconWysiwygMention,
  'xls-solid': IconXlsSolid,
};
